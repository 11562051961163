import React, { Component } from 'react'
import { Button, message, Empty, Modal, Input, Tooltip, } from 'antd';
import "./styleCss.css"
import BroadcastList from "./liveBroadcast_page/broadcastList"
import BroadcastHome from "./liveBroadcast_page/broadcastHome"
export default class LiveBroadcast extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Page:1,
            Item:{},
        }

    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div className='courseContent' style={{minWidth: "1400px"}} >
                <div style={{ width: "1400px", }}>
                   {this.state.Page==1?<BroadcastList setPage={(page,item) => this.setPage(page,item)}></BroadcastList>:""}
                   {this.state.Page==2?<BroadcastHome Item={this.state.Item} setPage={(page) => this.setPage(page)}></BroadcastHome>:""}
                </div>
            </div>
        )
    }
    componentDidMount() {
    }
    setPage(page,item){
        this.setState({Page:page,Item:item})
    }
}