import React, { Component } from 'react'
import service from '../../utlis/request';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, message, Select, Input, Space, Table, Upload, Spin, Watermark } from 'antd';
import PPT from '../../component/ppt_wbe365';
import "../styleCss.css"
import '../../index.css'
const { Option } = Select;
export default class Courseware extends Component {
    constructor(props) {
        super(props)
        this.state = {
            header: 'https://api2.yuexiaojing.com/v3/',
            ProvinceList: [{ Name: "北京" }],
            CityList: [{ Name: "北京" }],
            AreaList: [{ Name: "北京" }],
            CourseList: [],
            ResData: [],
            SortID: 1,
            List: [],
            Address: "",
            Name: "",
            Phone: "",
            ParentCode: "",
            CityCode: "",
            AreaCode: "",
            addSortVisible: false,
            addSortLoading: false,
            deleteSortVisible: false,
            deleteSortLoading: false,


            AddFileVisible: false,
            addFileLoading: false,
            deleteFileVisible: false,
            deleteFileLoading: false,
            viewVisible: false,
            SortName: "",

            FileName: "",
            File: "",
            FileList: [],
            FileID: 0,
            FilePath: '',
            DirectoryID: 0,


        }
    }
    ////////////////////////////////////////////////////////////////////////
    render() {
        const columns = [
            { title: '课件名称', dataIndex: 'Name', key: 'Name' },
            {
                title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
                    return <div>
                        <Button onClick={() => this.setState({ FilePath: record.Path }, () => {
                            this.setState({ viewVisible: true })
                        })} style={{ color: "#7AC4D9", }} >查看</Button>
                        <Button onClick={() => this.setState({ FileID: record.ID }, () => { this.setState({ deleteFileVisible: true }) })} style={{ marginLeft: "20px", color: "#7AC4D9", }}>删除</Button>
                    </div>
                }
            },
        ];
        return (
            <div style={{}}>
                <div style={{ width: "100%", height: "60px", padding: "10px 20px", borderBottom: "1px solid #EBEBEB", }}>
                    <div style={{ height: "40px", width: "100%", display: "flex", borderLeft: "6px solid #7AC4D9", lineHeight: "40px", fontSize: "18px", color: "#7AC4D9", paddingLeft: "20px" }}>
                        课件管理
                    </div>
                </div>
                <div>

                    <div style={{ display: "flex" }}>
                        <div style={{ flex: "4", padding: "15px" }}>
                            <div style={{ width: "100%", borderRadius: "4px", background: "#fff", boxShadow: " 0px 0px 2px 2px rgba(0,0,0,.2)" }}>
                                {
                                    this.state.CourseList.map((item, index) => {
                                        return <div style={{ display: "flex", textAlign: "center", cursor: "pointer", width: "100%", height: "40px", background: this.state.SortID == item.ID ? "#7AC4D9" : "#FFF", color: this.state.SortID == item.ID ? "#FFF" : "#7AC4D9", textAlign: "center", lineHeight: "40px" }}>
                                            <div style={{ width: "120px", margin: "0px 15px", }} onClick={() => { this.setState({ SortID: item.ID }, () => { this.getFile() }) }}>{item.Name ? (item.Name.length > 8 ? item.Name.substring(0, 8) : item.Name) : ""}</div>
                                            {index == 0 ? "" : <DeleteOutlined onClick={() => { this.setState({ SortID: item.ID }, () => { this.setState({ deleteSortVisible: true }) }) }}></DeleteOutlined>}
                                        </div>

                                    })
                                }
                            </div>
                        </div>
                        <div style={{ flex: "16", padding: "0px 10px" }}>
                            <div style={{ display: "flex", height: "50px", padding: "8px 10px", justifyContent: "flex-end" }}>
                                <Button onClick={() => { this.setState({ addSortVisible: true }) }} type="primary" style={{ width: "100px", border: "none", marginRight: "20px", background: "#7AC4D9" }}>添加分类</Button>
                                <Button onClick={() => { this.setState({ AddFileVisible: true }) }} type="primary" style={{ width: "100px", border: "none", background: "#7AC4D9" }}>上传课件</Button>
                            </div>
                            {/* 添加分类 */}
                            <Spin spinning={this.state.addSortLoading} tip="加载中......" size="large">
                                <Modal
                                    destroyOnClose={true}
                                    centered={true}
                                    title={null}
                                    footer={null}
                                    modalRender={(modal) => (
                                        modal
                                    )}
                                    // bodyStyle={{ padding: '0px' }}
                                    open={this.state.addSortVisible}
                                    onCancel={() => this.setState({ addSortVisible: false })}
                                    closable={true}
                                    width={500}
                                >
                                    <div style={{ heighe: "100%", }}>
                                        <div style={{ width: "100%", height: "40px", lineHeight: "40px", color: "#8291A9", fontSize: "20px", marginBottom: "20px" }}>添加分类</div>
                                        <div >
                                            <Space align="center">
                                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>分类名称：</div>
                                                <Input style={{ width: "300px", }} value={this.state.SortName} onChange={(e) => {
                                                    if (e.target.value.length <= 8) {
                                                        this.setState({ SortName: e.target.value })
                                                    } else {
                                                        message.error("分类名字最多输入8位")
                                                    }

                                                }} />
                                            </Space>
                                        </div>
                                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                                            <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => this.saveAddSort()}>确认</Button>
                                            <Button style={{ color: "#7AC4D9", }} onClick={() => this.setState({ addSortVisible: false })}>取消</Button>
                                        </div>
                                    </div>
                                </Modal>
                            </Spin>
                            {/* 删除分类 */}
                            <Spin spinning={this.state.deleteSortLoading} tip="加载中......" size="large">
                                <Modal
                                    destroyOnClose={true}
                                    centered={true}
                                    title={null}
                                    footer={null}
                                    modalRender={(modal) => (
                                        modal
                                    )}
                                    // bodyStyle={{ padding: '0px' }}
                                    open={this.state.deleteSortVisible}
                                    onCancel={() => this.setState({ deleteSortVisible: false })}
                                    closable={true}
                                    width={400}
                                >
                                    <div style={{ heighe: "205px", padding: "10px 20px" }}>
                                        <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                                        <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                                            <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => { this.DeleteCourse() }}>确认</Button>
                                            <Button style={{ color: "#7AC4D9", }} onClick={() => { this.setState({ deleteSortVisible: false }) }}>取消</Button>
                                        </div>
                                    </div>
                                </Modal>
                            </Spin>
                            {/* 上传课件 */}
                            <Spin spinning={this.state.addFileLoading} tip="加载中......" size="large">
                                <Modal
                                    destroyOnClose={true}
                                    centered={true}
                                    title={null}
                                    footer={null}
                                    modalRender={(modal) => (
                                        modal
                                    )}
                                    // bodyStyle={{ padding: '0px' }}
                                    open={this.state.AddFileVisible}
                                    onCancel={() => this.setState({ AddFileVisible: false })}
                                    closable={true}
                                    width={500}
                                >
                                    <div style={{ heighe: "100%", }}>
                                        <div style={{ width: "100%", height: "40px", lineHeight: "40px", color: "#8291A9", fontSize: "20px", marginBottom: "20px" }}>添加分类</div>
                                        <div >
                                            <Space
                                                direction="vertical"
                                                size="large"
                                                style={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Space align="center">
                                                    <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>分类：</div>
                                                    <Select
                                                        showSearch
                                                        style={{ width: "300px", }}
                                                        placeholder="分类"
                                                        optionFilterProp="children"
                                                        defaultActiveFirstOption={true}
                                                        dropdownMatchSelectWidth={true}
                                                        // value={this.state.TrainID}
                                                        onChange={(value) => {
                                                            this.setState({ DirectoryID: value })
                                                        }}
                                                    >
                                                        {this.state.ResData.map((item, index) => (
                                                            <Option key={item.ID} value={item.ID} >
                                                                {item.Name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Space>
                                                <Space align="center">
                                                    <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>课件名称：</div>
                                                    <Input style={{ width: "300px", }} value={this.state.FileName} onChange={(e) => { this.setState({ FileName: e.target.value }) }} />
                                                </Space>
                                                <Space align="center"  >
                                                    <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>课件：</div>
                                                    <Upload accept={".ppt"} maxCount={1} name="logo" listType="picture" beforeUpload={this.beforeUpload} onChange={(f) => { this.UploadVideo(f) }} fileList={this.state.FileList}  >
                                                        <Button style={{ background: "#7AC4D9", color: "#fff" }}>上传课件</Button>
                                                    </Upload>
                                                </Space>
                                                <Space align="center"  >
                                                    <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>提示：</div>
                                                    <div style={{ color: "#8291A9" }}>
                                                        上传的文件大小必须小于100M，且格式为ppt格式
                                                    </div>
                                                </Space>

                                            </Space>
                                        </div>
                                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                                            <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => this.saveAddCourseware()}>确认</Button>
                                            <Button style={{ color: "#7AC4D9", }} onClick={() => this.setState({ AddFileVisible: false })}>取消</Button>
                                        </div>
                                    </div>
                                </Modal>
                            </Spin>
                            {/* 查看课时 */}
                            <Modal
                                destroyOnClose={true}
                                centered={true}
                                title={null}
                                footer={null}
                                modalRender={(modal) => (
                                    modal
                                )}
                                // bodyStyle={{ padding: '0px' }}
                                open={this.state.viewVisible}
                                onCancel={() => this.setState({ viewVisible: false })}
                                closable={true}
                                width={900}
                                style={{ minWidth: "900px" }}
                            >
                                <div style={{ height: "700px", padding: "10px", borderRadius: "12px", }}>
                                    <div style={{ width: "812px", height: "680px", borderRadius: "12px", }}>
                                        <PPT path={this.state.FilePath} />
                                    </div>
                                </div>
                                {/* <div style={{ height: "880px", padding: "10px 20px" }}>
                                    <div style={{ width: "880px", height: "680px", background: "#000", borderRadius: "12px" }}>
                                        <PPT path={this.state.FilePath} />
                                    </div>
                                </div> */}
                            </Modal>
                            {/* 删除课件 */}
                            <Spin spinning={this.state.deleteFileLoading} tip="加载中......" size="large">
                                <Modal
                                    destroyOnClose={true}
                                    centered={true}
                                    title={null}
                                    footer={null}
                                    modalRender={(modal) => (
                                        modal
                                    )}
                                    // bodyStyle={{ padding: '0px' }}
                                    open={this.state.deleteFileVisible}
                                    onCancel={() => this.setState({ deleteFileVisible: false })}
                                    closable={true}
                                    width={400}
                                >
                                    <div style={{ heighe: "205px", padding: "10px 20px" }}>
                                        <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                                        <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                                            <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => { this.DeleteFile() }}>确认</Button>
                                            <Button style={{ color: "#7AC4D9", }} onClick={() => { this.setState({ deleteFileVisible: false }) }}>取消</Button>
                                        </div>
                                    </div>
                                </Modal>
                            </Spin>
                            <Table columns={columns}
                                style={{ color: "" }}
                                // rowSelection={rowSelection}
                                dataSource={this.state.List}
                                className="table"
                                bordered={true}
                                pagination={false}
                                rowKey={record => record.ID}
                                size='small'
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    componentDidMount() {
        this.getCourse()
        this.getFile()
    }
    getCourse() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/directory',
            params: {
                offset: 0,
                limit: 0,
                isOwn: 1,
            }
        }).then(res => {
            if (res.code === 200) {
                var list = [{ Name: "我复制的课件", ID: 1, },]
                if (res.data.length > 0) {
                    res.data.map((item, index) => {
                        list.push(item)
                    })
                    this.setState({
                        SortID: list[0].ID,
                        CourseList: list,
                        ResData: res.data
                    }, () => {
                        this.getFile()
                    })
                } else {
                    this.setState({
                        CourseList: list,
                    })
                }
            } else {
                message.error("系统繁忙，请重试")
            }
        })
    }
    getFile() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/material',
            params: {
                offset: 0,
                limit: 0,
                directoryID: this.state.SortID,
                isOwn: 1,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    List: res.data,
                })
            } else {
                message.error("系统繁忙，请重试")
            }
        })
    }
    saveAddSort() {
        if (this.state.SortName != "") {
            this.setState({
                addSortLoading: true
            })
            service.request({
                url: service.api.homeURL + '/directory',
                method: "post",
                data: {
                    Name: this.state.SortName
                },
            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        addSortLoading: false,
                        addSortVisible: false,
                    })
                    this.getCourse()
                    message.success("添加成功")
                } else {
                    this.setState({
                        addSortLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    }
                    else {
                        message.error("添加失败，请重试")
                    }
                }
            })
        } else {
            message.error("输入不能为空")
        }

    }
    // 删除分类
    DeleteCourse() {
        service.request({
            url: service.api.homeURL + '/directory',
            method: "delete",
            data: {
                ID: this.state.SortID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.setState({ deleteSortVisible: false })
                this.getCourse()
                message.success('删除成功');
            } else {
                if (res.code == 451) {
                    message.error('该分类下面有课件，无法删除');
                } else {
                    message.error('删除失败，请重试');
                }
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
    saveAddCourseware() {
        var form = new FormData
        if (this.state.FileName != "" && this.state.File != "" && this.state.DirectoryID != 0) {
            form.set("DirectoryID", this.state.DirectoryID)
            form.set("Name", this.state.FileName)
            form.set("File", this.state.File)
            this.setState({
                AddFileLoading: true,
            })
            service.request({
                url: service.api.homeURL + '/material',
                method: "post",
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: form,

            }).then(res => {
                if (res.code == 200) {
                    this.setState({
                        AddFileLoading: false,
                        AddFileVisible: false,
                        FileName: "",
                        File: "",
                        FileList: [],
                    })
                    this.getFile()
                    message.success("添加成功")
                } else {
                    this.setState({
                        AddFileLoading: false,
                    })
                    if (res.code == 403) {
                        message.error("该名称已存在！")
                    } else if (res.code == 456) {
                        message.error("添加失败，上传ppt已达上限")
                    }
                    else if (res.code == 401) {
                        message.error("添加失败，请选择分类")
                    }
                    else {
                        message.error("添加失败，请重试")
                    }
                }
            })
        } else {
            message.error('输入不能为空');
        }

    }
    DeleteFile() {
        service.request({
            url: service.api.homeURL + '/material',
            method: "delete",
            data: {
                ID: this.state.FileID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.setState({ deleteFileVisible: false })
                this.getFile()
                message.success('删除成功');
            } else {
                if (res.code == 451) {
                    message.error('该分类下面有课件，无法删除');
                } else {
                    message.error('删除失败，请重试');
                }
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }

    UploadVideo(f) {
        if (f.file.size / 1024 / 1024 < 100) {
            this.setState({ File: f.file, FileList: f.fileList })
        } else {
            message.error("上传的文件大小必须小于100M")
        }
    }

    beforeUpload() {
        return false
    }
}