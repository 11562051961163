import React, { Component } from 'react'
import axios from 'axios';
// import * as styled from '../utlis/styled'
import cookie from "react-cookies";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Input, message, notification } from 'antd';
import "./styleCss.css"

const openNotificationWithIcon = () => {
    notification["error"]({
        message: '异地登陆',
        description: '请注意，您登陆的所在地与注册学校所在地不符，请尽快下线！',
        duration: 0,
        // style:{
        //     top:100
        // },
    });
};
export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Username: "",
            Password: "",
            header: "https://api2.yuexiaojing.com/v3/",
        }
    }
    render() {
        return (
            <div className='loginContent' style={{ zIndex: "10", minWidth: "1200px" }}>


                <div style={{ height: "365px", width: "460px", background: "#fff", borderRadius: "6px" }}>
                    <div style={{ width: "100%", height: "97px", textAlign: "center", lineHeight: "97px", fontSize: "28px", color: "#83cfdc", borderRadius: "6px 6px 0px 0px", background: "#f1f4f9", marginBottom: "30px" }}>
                        悦小鲸官网平台登陆
                    </div>
                    <div style={{ padding: "0px 60px", marginBottom: "20px" }}>
                        <Input placeholder="账号" prefix={<UserOutlined />} value={this.state.Username} onChange={(e) => {
                            this.setState({ Username: e.target.value, })
                        }} style={{ marginBottom: "20px", border: "1px solid #b1b1b1", height: "40px" }} />
                        <Input type='password' placeholder="密码" prefix={<LockOutlined />} value={this.state.Password} onChange={(e) => {
                            this.setState({ Password: e.target.value, })
                        }} style={{ marginBottom: "20px", border: "1px solid #b1b1b1", height: "40px" }} />
                        <Button style={{ width: "100%", height: "46px", fontSize: "20px", color: "#fff", background: "#53bfe5", borderRadius: "6px" }} onClick={() => this.login()}>登录</Button>
                    </div>
                    <div style={{ padding: "0px 26px", color: "#83cfdc", fontSize: "16px" }}>温馨提示：仅限悦小鲸签约合作校登录，禁止异地登陆</div>
                    <div style={{ position: "absolute", bottom: "10px", left: "50px", fontSize: "13px", width: "1600px", color: "#fff" }}><span>河北悦小鲸教育科技有限公司</span><a style={{ color: "#fff", paddingLeft: "20px" }} href="https://beian.miit.gov.cn">冀ICP备2021024602号-1</a></div>
                </div>
            </div>
        )
    }
    componentDidMount() {
    }
    login() {
        // if (navigator.geolocation) {
        //     console.log("0:", navigator.geolocation);
        //     navigator.geolocation.getCurrentPosition(
        //         (position) => {
        //             const { latitude, longitude } = position.coords;
        //             this.setState({ latitude: latitude, longitude: longitude })
        //             console.log("success:", latitude, longitude);
        //         },
        //         (error) => {
        //             console.log("err:", error);
        //         }
        //     );
        // } else {
        //     console.log('Geolocation is not supported by this browser.');
        // }
        //
        if (this.state.Username == '' || this.state.Password == '') {
            message.error('用户名密码不能为空');
        } else {
            axios.post('https://api2.yuexiaojing.com/v3/login', {
            // axios.post('http://127.0.0.1:8097/login', {
                Username: this.state.Username,
                Password: this.state.Password
            }).then(res => {
                if (res.data.code != 200) {
                    if (res.data.code == 410) {
                        message.error('该用户不存在');
                        return
                    }
                    if (res.data.code == 411) {
                        message.error('该用户的登录权限已到期');
                        return
                    }
                    if (res.data.code == 412) {
                        message.error('该用户已被禁止登录');
                        return
                    }
                    if (res.data.code == 413) {
                        message.error('用户名密码错误');
                        return
                    }
                    message.error('账号异常，请联系管理员');
                } else {
                    let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
                    cookie.save("token", res.data.data.Token, { expires: inFifteenMinutes })
                    //
                    global.DownloadPhase = res.data.data.DownloadPhase
                    global.ViewPhase = res.data.data.ViewPhase
                    global.Identity = res.data.data.User.Identity
                    global.IsLive = res.data.data.User.IsLive
                    global.Watermark = res.data.data.Watermark
                    global.NickName = res.data.data.Watermark + "--" + res.data.data.User.NickName
                    if (res.data.data.User.FaceURL != "") {
                        global.FaceURL = this.state.header + res.data.data.User.FaceURL
                    }
                    //
                    this.props.history.push('/')
                    if (this.props.location.state) {
                        this.props.location.state.getinfo()
                    }
                    this.setState({
                        Username: "",
                        Password: "",
                    })
                    if (!res.data.data.Offsite) {
                        // openNotificationWithIcon()
                    }
                }
            }).catch(function (error) {
                console.log("catch", error);
            });
        }
    }
}