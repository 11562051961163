import React, { Component } from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import { pageRoutes, mainRoutes, renderRoutesMain } from './routes/index';
import cookie from "react-cookies";
import Index from './component/index';
import service from './utlis/request';

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  //
  componentDidMount() {
    this.timer = setInterval(() => {
      if (this.checkLogin()) {
        this.updateOnlineTime()
      }
    }, 1000 * 60 * 10)
  }
  componentWillUnmount() {
    if (this.timer != null) {
      clearInterval(this.timer)
    }
  }
  //
  render() {
    return this.checkLogin() ? (
      <Index history={this.props.history}>
        {renderRoutesMain(pageRoutes)}
        {/* {renderRoutesMain(mainRoutes)} */}
      </Index>
    ) : (<Redirect to="/login" />)
    // return (
    //   <Index history={this.props.history}>
    //     {renderRoutesMain(pageRoutes)}
    //     {/* {renderRoutesMain(mainRoutes)} */}
    //   </Index>
    // )
  }

  //
  updateOnlineTime() {
    service.request({
      method: 'put',
      url: service.api.homeURL + '/updateOnlineTime',
    }).then(res => {
      if (res.code !== 200) {
        console.log("更新时长失败")
      }
    })
  }

  checkLogin() {
    var token = cookie.load("token")
    if (token) {
      return true
    }
    //
    return false
  }
}

