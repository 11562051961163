import React, { Component } from 'react'
import { FullscreenOutlined, CloudDownloadOutlined, DownloadOutlined, CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Empty, Modal, Spin, Tooltip, Watermark } from 'antd';
import service from '../../../utlis/request'
import PPT from '../../../component/ppt_wbe365';
import VideoPlayer from '../../../component/video'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import xiala from '../../../image/xiala.png';
import c1 from '../../../image/c1.png';
import c2 from '../../../image/c2.png';
import c3 from '../../../image/c3.png';
import c4 from '../../../image/c4.png';
export default class CourseSystem_file extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // PhaseList: [{ Name: "启迪篇", ID: 1, }, { Name: "基础篇", ID: 2, }, { Name: "工具课程", ID: 3, }, { Name: "特色课程", ID: 4, }],
            FileList: [],
            ClassID: 0,
            nav_arr: [],
            header: 'https://api2.yuexiaojing.com/v3/',
            ResourcesTypeList: [],
            CoursewareList: [],
            CoursewarePath: '',
            CoursewareIndex: 0,
            resourcesTypeName: 0,
            //
            VideoList: [],
            VideoPath: '',
            AudioIndex: 0,
            //
            AudioList: [],
            AudioPath: '',
            VideoIndex: 0,
            //
            LessonPlanList: [],
            LessonPlanPath: '',
            LessonPlanIndex: 0,

            CopyItem: {},
            CopyFileLoading: false,
            CopyFileVisible: false,

            IsDownloadPhase: false
        }

    }
    ////////////////////////////////////////////////////////////////////////
    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                {this.state.nav_arr.length > 0 ? <div style={{ display: "flex", position: "relative", marginBottom: "15px", }}>
                    {
                        this.state.nav_arr.map((item, index) => {
                            if (index < 1) {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    {item.Name}
                                </div>
                            } else {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    <span>{">"}</span>
                                    {item.Name} </div>
                            }

                        })
                    }
                </div> : ''}
                <div style={{ display: "flex", marginTop: "35px", }}>

                    <div style={{ width: "880px", height: "604px", background: "#47b3d8", borderRadius: "12px" }}>{this.renderResources()}</div>

                    <div style={{ width: "300px", marginLeft: "20px", borderRadius: "12px", padding: "5px" }}>

                        <div style={{ display: "flex", width: "100%", flexDirection: 'column' }}>
                            {this.renderTypeList()}
                            <Spin spinning={this.state.CopyFileLoading} tip="加载中......" size="large">
                                <Modal
                                    destroyOnClose={true}
                                    centered={true}
                                    title={null}
                                    footer={null}
                                    modalRender={(modal) => (
                                        modal
                                    )}
                                    // bodyStyle={{ padding: '0px' }}
                                    open={this.state.CopyFileVisible}
                                    onCancel={() => this.setState({ CopyFileVisible: false })}
                                    closable={true}
                                    width={400}
                                >
                                    <div style={{ heighe: "205px", padding: "10px 20px" }}>
                                        <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                                        <div style={{ marginTop: "20px", color: "#666666" }}>确定复制此条数据？</div>
                                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                                            <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => { this.CopyFile() }}>确认</Button>
                                            <Button style={{ color: "#7AC4D9", }} onClick={() => { this.setState({ CopyFileVisible: false }) }}>取消</Button>
                                        </div>
                                    </div>
                                </Modal>
                            </Spin>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.setState({ ClassID: this.props.ClassItem.ID, nav_arr: this.props.nav_arr, IsDownloadPhase: this.props.IsDownloadPhase }, () => {
            this.getCourseware(true)
        })
    }
    getCourseware(b) {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/resources',
            params: {
                offset: 0,
                limit: 0,
                classID: this.state.ClassID,
                type: "课件",
            }
        }).then(res => {
            if (res.code === 200) {
                if (res.data.length == 0) {
                    this.setState({
                        CoursewareList: [],
                        CoursewarePath: '',
                        // resourcesTypeName: '',
                    }, () => {
                        if (b) {
                            this.getVideo(b)
                        }
                    })
                    return
                } else {
                    if (b) {
                        this.getVideo(b)
                    }
                }
                this.setState({
                    CoursewareList: res.data,
                    CoursewarePath: res.data[0].Path,
                })
            } else {
            }
        })
    }
    getVideo(b) {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/resources',
            params: {
                offset: 0,
                limit: 0,
                classID: this.state.ClassID,
                type: "视频",
            }
        }).then(res => {
            if (res.code === 200) {
                if (res.data.length == 0) {
                    this.setState({
                        VideoList: [],
                        VideoPath: '',
                        // resourcesTypeName: '',
                    }, () => {
                        if (b) {
                            this.getAudio(b)
                        }
                    })
                    return
                } else {
                    if (b) {
                        this.getAudio(b)
                    }
                }
                this.setState({
                    VideoList: res.data,
                    VideoPath: res.data[0].Path,
                })
            } else {
            }
        })
    }
    getAudio(b) {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/resources',
            params: {
                offset: 0,
                limit: 0,
                classID: this.state.ClassID,
                type: "音频",
            }
        }).then(res => {
            if (res.code === 200) {
                if (res.data.length == 0) {
                    this.setState({
                        AudioList: [],
                        AudioPath: '',
                        // resourcesTypeName: '',
                    }, () => {
                        if (b) {
                            this.getLessonPlan(b)
                        }
                    })
                    return
                } else {
                    if (b) {
                        this.getLessonPlan(b)
                    }
                }
                this.setState({
                    AudioList: res.data,
                    AudioPath: res.data[0].Path,
                })
            } else {
            }
        })
    }
    getLessonPlan() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/resources',
            params: {
                offset: 0,
                limit: 0,
                classID: this.state.ClassID,
                type: "教案",
            }
        }).then(res => {
            if (res.code === 200) {
                if (res.data.length == 0) {
                    this.setState({
                        LessonPlanList: [],
                        LessonPlanPath: '',
                        // resourcesTypeName: '',
                    })
                    return
                }
                this.setState({
                    LessonPlanList: res.data,
                    LessonPlanPath: res.data[0].Path,
                })
            } else {
            }
        })
    }
    switchPage(index) {
        if (index == 0) {
            var arr = []
            arr.push(this.state.nav_arr[index])
            this.props.setPage(1, arr)
        }
        if (index == 1) {
            //
            var arr = []
            arr.push(this.state.nav_arr[0])
            this.props.setPage(1, arr)
        }
        if (index == 2) {
            this.state.nav_arr.splice(index + 1, 1)
            var arr = [...this.state.nav_arr]
            this.props.setPage(3, arr)
        }

    }
    renderTypeList() {
        var TypeList = []
        if (this.state.CoursewareList.length > 0) {
            TypeList.push({ ID: 1, Name: "课件" })
        }
        if (this.state.VideoList.length > 0) {
            TypeList.push({ ID: 2, Name: "视频" })
        }
        if (this.state.AudioList.length > 0) {
            TypeList.push({ ID: 3, Name: "音频" })
        }
        if (this.state.LessonPlanList.length > 0) {
            TypeList.push({ ID: 4, Name: "教案" })
        }
        if (TypeList.length > 0 && this.state.resourcesTypeName == 0) {
            this.setState({ resourcesTypeName: TypeList[0].ID })
        }
        return TypeList.map((item, index) => {
            return <div style={{ marginBottom: "25px" }}>
                <div id={item.ID} key={index} onMouseEnter={() => this.enter(item.ID, index)} onMouseLeave={() => this.remove(item.ID, index)} onClick={() => this.switchResourcesType(item.ID)} index={index} style={{ width: "100%", height: "70px", fontSize: "25px", cursor: "pointer", borderRadius: "12px", padding: "21px", display: "flex", position: "relative" }}>
                    <div id={"n" + item.ID} style={{ borderRadius: "12px", padding: "15px", marginBottom: "25px", width: "100%", height: "100%", background: "#fff", position: "absolute", zIndex: "-1", top: "0", left: "0" }}></div>
                    <div id={"y" + item.ID} className='courseSwitch2' style={{ display: this.state.resourcesTypeName == item.ID ? "" : "none", borderRadius: "12px", padding: "15px", marginBottom: "25px", width: "100%", height: "100%", position: "absolute", zIndex: "-1", top: "0", left: "0" }}></div>
                    <div style={{ lineHeight: "28px", color: "#000", marginLeft: "10px", fontSize: "18px" }}>{item.Name}</div>
                    {this.renderIcon(item)}
                    <img id={"x" + item.ID} style={{ position: "absolute", right: "-50px", top: "19px", animation: this.state.resourcesTypeName == item.ID ? '0.5s ease 0s 1 normal forwards running rotate' : '' }} src={xiala}></img>
                </div>
                <div style={{ display: this.state.resourcesTypeName == item.ID ? "" : "none", width: "calc(100% - 30px)", minHeight: "40px", background: "#fff", margin: 'auto', borderRadius: "0 0 12px 12px", textAlign: "center" }}>
                    {this.renderResourcesList()}
                </div>
            </div>
        })
    }
    renderIcon(item) {
        switch (item.ID) {
            case 1:
                return <img id={"i" + item.ID} style={{ marginLeft: "20px", width: "27px", height: "27px", objectFit: "contain", borderRadius: "6px", background: this.state.resourcesTypeName == item.ID ? '#fff' : '#eefaff' }} src={c1}></img>
            case 2:
                return <img id={"i" + item.ID} style={{ marginLeft: "20px", width: "27px", height: "27px", objectFit: "contain", borderRadius: "6px", background: this.state.resourcesTypeName == item.ID ? '#fff' : '#eefaff' }} src={c2}></img>
            case 3:
                return <img id={"i" + item.ID} style={{ marginLeft: "20px", width: "27px", height: "27px", objectFit: "contain", borderRadius: "6px", background: this.state.resourcesTypeName == item.ID ? '#fff' : '#eefaff' }} src={c3}></img>
            case 4:
                return <img id={"i" + item.ID} style={{ marginLeft: "20px", width: "27px", height: "27px", objectFit: "contain", borderRadius: "6px", background: this.state.resourcesTypeName == item.ID ? '#fff' : '#eefaff' }} src={c4}></img>
        }
    }
    enter(ID) {
        var doc = document.getElementById("y" + ID)
        doc.style.display = ''

        var img = document.getElementById("i" + ID)
        img.style.background = '#fff'
    }
    remove(ID) {
        if (this.state.resourcesTypeName != ID) {
            var doc = document.getElementById("y" + ID)
            doc.style.display = 'none'

            var img = document.getElementById("i" + ID)
            img.style.background = '#eefaff'
        }
    }
    enterCourse(ID) {
        var doc = document.getElementById("z" + ID)
        doc.style.background = '#f3f9fb'

        var img = document.getElementById("zi" + ID)
        img.style.background = '#fff'
    }
    removeCourse(ID) {
        if (this.state.resourcesTypeName != ID) {
            var doc = document.getElementById("z" + ID)
            doc.style.background = '#fff'

            var img = document.getElementById("zi" + ID)
            img.style.background = '#eefaff'
        }
    }
    //
    renderResources() {
        switch (this.state.resourcesTypeName) {
            case 1:
                // return <Watermark style={{ width: "100%", height: "100%" }} content={global.Watermark} gap={[500,500]}>
                //     <iframe title="ppt" width="100%" height="100%" frameBorder="0" src={"https://vip.ow365.cn?i=30293&ssl=1&furl=" + this.state.header + this.state.CoursewarePath}></iframe>
                // </Watermark>
                return <PPT path={this.state.CoursewarePath} />
            case 2:
                return <div style={{ width: "880px", height: "604px", background: "#000", borderRadius: "12px" }}>
                    {this.state.VideoList.length > 0 ?
                        <VideoPlayer controls src={this.state.header + this.state.VideoPath} />
                        // <video preload="auto" width="100%" height="100%" controls src={this.state.header + this.state.VideoPath}></video>
                        : <></>}
                </div>
            case 3:
                return <div style={{ width: "880px", height: "604px", background: "#000", borderRadius: "12px", display: "flex", justifyContent: 'center', alignItems: "center", }}>
                    {this.state.AudioList.length > 0 ?
                        <AudioPlayer src={this.state.header + this.state.AudioPath} />
                        // <audio controls src={this.state.header + this.state.AudioPath}></audio>
                        : <></>}
                </div>
            case 4:
                return <Watermark style={{ width: "100%", height: "100%" }} content={global.Watermark} gap={[500,500]}>
                    {/* <div style={{ width: "100%", height: "78px", background: "#393a3d", position: "absolute", top: "0px", left: "0px" }}></div> */}
                    {/* {this.state.LessonPlanList.length > 0 ? <iframe title="ppt" width="100%" height="100%" frameBorder="0" src={"https://psg3-powerpoint.officeapps.live.com/op/view.aspx?src=" + this.state.header + this.state.LessonPlanPath}></iframe> : <></>} */}
                    <iframe title="ppt" width="100%" height="100%" frameBorder="0" src={"https://vip.ow365.cn?i=30293&ssl=1&furl=" + this.state.header + this.state.LessonPlanPath}></iframe>
                </Watermark>
        }
    }
    switchResourcesType(ID) {
        if (ID == this.state.resourcesTypeName) {
            return
        }
        this.setState({
            resourcesTypeName: ID,
        }, () => {
            if (ID == 1) {
                this.getCourseware()
            }
            if (ID == 2) {
                this.getVideo()
            }
            if (ID == 3) {
                this.getAudio()
            }
            if (ID == 4) {
                this.getLessonPlan()
            }
        })
    }
    renderResourcesList() {
        switch (this.state.resourcesTypeName) {
            case 1:
                return <div style={{ overflow: "auto", padding: "5px" }}>
                    {
                        this.state.CoursewareList.map((item, index) => {
                            return <div style={{ width: "100%", height: "40px", padding: "2px", marginBottom: "8px", display: "flex", color: '#000', background: this.state.CoursewareIndex == index ? "rgb(229 245 250)" : '#fff', }}>
                                <Tooltip color='#2db7f5' placement="bottom" title={item.Name}><div onClick={() => this.selectedResourcesItem(1, item, index)} style={{ width: "100%", height: "34px", lineHeight: "34px", fontSize: "15px", cursor: "pointer", }}>
                                    {item.Name ? (item.Name.length > 12 ? item.Name.substring(0, 12) + "..." : item.Name) : ""}</div></Tooltip>
                                {item.IsDownload == 1 ? (this.state.IsDownloadPhase ? <DownloadOutlined onClick={(e) => this.download(e, item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /> : "") : ""}
                                <Tooltip color='#2db7f5' placement="bottom" title="复制"><CopyOutlined onClick={() => this.CopyPath(item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /></Tooltip>

                            </div>
                        })
                    }
                </div>
            case 2:
                return <div style={{ overflow: "auto", padding: "5px" }}>
                    {
                        this.state.VideoList.map((item, index) => {
                            return <div style={{ width: "100%", height: "40px", padding: "2px", marginBottom: "8px", display: "flex", color: '#000', background: this.state.VideoIndex == index ? "rgb(229 245 250)" : '#fff', }}>
                                <Tooltip color='#2db7f5' placement="bottom" title={item.Name}><div onClick={() => this.selectedResourcesItem(2, item, index)} style={{ width: "100%", height: "34px", lineHeight: "34px", fontSize: "15px", cursor: "pointer", }}>
                                    {item.Name ? (item.Name.length > 12 ? item.Name.substring(0, 12) + "..." : item.Name) : ""}</div></Tooltip>
                                {/* {item.IsDownload == 1 ? (this.state.IsDownloadPhase ? <DownloadOutlined onClick={(e) => this.download(e, item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /> : "") : ""} */}
                                {item.IsDownload == 1 ? <DownloadOutlined onClick={(e) => this.download(e, item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /> : ""}
                            </div>
                        })
                    }
                </div>
            case 3:
                return <div style={{ overflow: "auto", padding: "5px" }}>
                    {
                        this.state.AudioList.map((item, index) => {
                            return <div style={{ width: "100%", height: "40px", padding: "2px", marginBottom: "8px", display: "flex", color: '#000', background: this.state.AudioIndex == index ? "rgb(229 245 250)" : '#fff', }}>
                                <Tooltip color='#2db7f5' placement="bottom" title={item.Name}><div onClick={() => this.selectedResourcesItem(3, item, index)} style={{ width: "100%", height: "34px", lineHeight: "34px", fontSize: "15px", cursor: "pointer", }}>
                                    {item.Name ? (item.Name.length > 12 ? item.Name.substring(0, 10) + "..." : item.Name) : ""}</div></Tooltip>
                                {/* {item.IsDownload == 1 ? (this.state.IsDownloadPhase ? <DownloadOutlined onClick={(e) => this.download(e, item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /> : "") : ""} */}
                                {item.IsDownload == 1 ? <DownloadOutlined onClick={(e) => this.download(e, item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /> : ""}
                            </div>
                        })
                    }
                </div>
            case 4:
                return <div style={{ overflow: "auto", padding: "5px" }}>
                    {
                        this.state.LessonPlanList.map((item, index) => {
                            return <div style={{ width: "100%", height: "40px", padding: "2px", marginBottom: "8px", display: "flex", color: '#000', background: this.state.LessonPlanIndex == index ? "rgb(229 245 250)" : '#fff', }}>
                                <Tooltip color='#2db7f5' placement="bottom" title={item.Name}><div onClick={() => this.selectedResourcesItem(4, item, index)} style={{ width: "100%", height: "34px", lineHeight: "34px", fontSize: "15px", cursor: "pointer", }}>
                                    {item.Name ? (item.Name.length > 12 ? item.Name.substring(0, 12) + "..." : item.Name) : ""}</div></Tooltip>
                                {/* {item.IsDownload == 1 ? (this.state.IsDownloadPhase ? <DownloadOutlined onClick={(e) => this.download(e, item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /> : "") : ""} */}
                                {item.IsDownload == 1 ? <DownloadOutlined onClick={(e) => this.download(e, item)} style={{ fontSize: "18px", color: "#7a7d7e", marginRight: "10px" }} /> : ""}
                            </div>
                        })
                    }
                </div>
        }
    }
    selectedResourcesItem(ID, item, index) {
        switch (ID) {
            case 1:
                this.setState({
                    CoursewareIndex: index,
                    CoursewarePath: item.Path,
                })
                return
            case 2:
                this.setState({
                    VideoIndex: index,
                    VideoPath: item.Path,
                })
                return
            case 3:
                this.setState({
                    AudioIndex: index,
                    AudioPath: item.Path,
                })
                return
            case 4:
                this.setState({
                    LessonPlanIndex: index,
                    LessonPlanPath: item.Path,
                })
        }
    }
    download(e, i) {
        e.stopPropagation()
        let a = document.createElement('a')
        a.style = 'display: none' // 创建一个隐藏的a标签
        // a.download = filename
        a.href = this.state.header + "getfile?filePath=" + i.Path
        document.body.appendChild(a)
        a.click() // 触发a标签的click事件
        document.body.removeChild(a)
    }
    CopyPath(item) {
        this.setState({
            CopyItem: item
        }, () => {
            this.setState({ CopyFileVisible: true })
        })
    }
    CopyFile() {
        this.setState({
            CopyFileLoading: true,
        })
        service.request({
            url: service.api.homeURL + '/resources/copyFile',
            method: "post",
            params: {
                resourcesID: this.state.CopyItem.ID,
            },
        }).then(res => {
            if (res.code == 200) {
                message.success("复制成功")
                this.setState({
                    CopyFileLoading: false,
                    CopyFileVisible: false,
                })
            } else {
                this.setState({
                    CopyFileLoading: false,
                })
                if (res.code == 456) {
                    message.error("添加失败，上传ppt已达上限")
                } else {
                    message.error('复制失败,请重试');
                }
            }
        })
    }


}