import React, { Component } from 'react'
import CourseSystem_phase from './courseSystem_page/courseSystem_phase'
import CourseSystem_class from './courseSystem_page/courseSystem_class'
import CourseSystem_collection from './courseSystem_page/courseSystem_collection'
import CourseSystem_file from './courseSystem_page/courseSystem_file'

const courseRef = React.createRef();

export default class CourseSystem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Page: 1,
            PhaseItem: {},
            CourseItem: {},
            ClassItem: {},
            nav_arr: [],
            bgc: "",
            IsDownloadPhase: false,
        }
    }
    ////////////////////////////////////////////////////////////////////////
    componentDidMount() {
    }
    render() {
        return (
            <div className='courseContent' ref={courseRef}>
                <div style={{ width: "1200px" }}>
                    {this.state.Page == 1 ? <CourseSystem_phase nav_arr={this.state.nav_arr} PhaseItem={this.state.PhaseItem} setPhase={(item, bgc) => this.setPhase(item, bgc)} getScrollTop={() => { this.getScrollTop() }} setScrollTop={() => { this.setScrollTop() }} setPage={(page, arr,) => this.setPage(page, arr)} setDownloadPhase={(is) => this.setDownloadPhase(is)} setCourse={(item) => this.setCourse(item)}></CourseSystem_phase> : ''}
                    {this.state.Page == 3 ? <CourseSystem_class nav_arr={this.state.nav_arr} CourseItem={this.state.CourseItem} setClass={(item) => this.setClass(item)} setPage={(page, arr) => this.setPage(page, arr)}></CourseSystem_class> : ''}
                    {this.state.Page == 4 ? <CourseSystem_collection nav_arr={this.state.nav_arr} CourseItem={this.state.CourseItem} IsDownloadPhase={this.state.IsDownloadPhase} setClass={(item) => this.setClass(item)} setPage={(page, arr) => this.setPage(page, arr)}></CourseSystem_collection> : ''}
                    {this.state.Page == 5 ? <CourseSystem_file nav_arr={this.state.nav_arr} ClassItem={this.state.ClassItem} IsDownloadPhase={this.state.IsDownloadPhase} setPage={(page, arr) => this.setPage(page, arr)}></CourseSystem_file> : ''}
                </div>
            </div>
        )
    }
    setDownloadPhase(is) {
        this.setState({
            IsDownloadPhase: is
        })
    }
    setPage(page, arr) {
        this.setState({ Page: page, nav_arr: arr })
    }
    setPhase(item, bgc) {
        this.setState({ PhaseItem: item, bgc: bgc })
    }
    setCourse(item) {
        this.setState({ CourseItem: item })
    }
    setClass(item) {
        this.setState({ ClassItem: item })
    }
    getScrollTop() {
        console.log(courseRef.current.scrollTop);
        this.setState({ scrollTop: courseRef.current.scrollTop })
    }
    setScrollTop() {
        console.log(this.state.scrollTop);
        courseRef.current.scrollTop = this.state.scrollTop
    }
}