import React, { Component } from 'react'
import axios from 'axios';
import { AppstoreOutlined, MailOutlined, BellOutlined, DownOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router';
import '../index.css'
import cookie from "react-cookies";
import { pageRoutes } from "../routes/index"
import service from '../utlis/request';
import { Dropdown, Space, Button, Input, Modal, message, Empty, notification } from 'antd';
import c1 from '../image/logo.png';

//
class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            LoginVisible: false,
            MessageVisible: false,
            Download: [],
            View: [],
            Username: "",
            Password: "",
            // user: cookie.load("user"),
            user: { Identity: 0, Name: '名字', FaceURL: '' },
            userInfo: {},
            header: "https://api2.yuexiaojing.com/v3/",
            FaceURL: "",
        }

    }

    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{ width: "100%", height: "100%", minWidth: "1200px" }}>
                <div style={{ width: "100%", height: "100%", zIndex: "1000", position: "relative" }}>
                    <div style={{ width: "100%", height: "68px", background: "rgb(0 0 0)", opacity: "0.3", position: 'absolute' }}></div>
                    <div style={{ width: "100%", height: "68px", display: "flex", justifyContent: 'space-between', flexDirection: 'row', }}>
                        <div style={{ display: "flex", width: "1000px", height: "68px", marginLeft: "50px" }}>
                            <img src={c1} style={{ width: "52px", height: "46px", marginTop: "13px", zIndex: "1000" }}></img>
                            <div style={{ display: "flex", marginLeft: "50px", }}>
                                {pageRoutes.map((item, index) => {
                                    if (item.isShow) {
                                        return (
                                            <div index={index} key={index} active={this.isActive(item) ? 1 : 0} style={{ width: "130px", height: "68px", lineHeight: "68px", cursor: 'pointer', position: "relative" }}>
                                                {this.isActive(item) ? <div style={{ background: "rgb(71 179 216)", width: "55%", height: "5px", margin: "auto", position: "relative", zIndex: "1" }}></div> : ''}
                                                <div
                                                    onClick={() => this.changeTitle(item)}
                                                    style={{ color: '#fff', textShadow: this.isActive(item) ? "0 0 10px rgb(30 138 210), 0 0 20px rgb(30 138 210), 0 0 30px rgb(30 138 210), 0 0 40px rgb(30 138 210)" : "", textDecorationLine: 'none', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: "18px", position: "absolute", top: "0" }}
                                                >
                                                    {item.name}
                                                </div>
                                                <div />
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <div style={{ display: "flex", width: "200px", height: "68px", }}>
                            <Button onClick={() => this.goPage("/Notice")} style={{ height: "68px", width: "100px", fontSize: "16px", }} type="link">
                                <BellOutlined style={{ color: "#fff", fontSize: "26px" }}></BellOutlined>
                            </Button>
                            {cookie.load("token") == undefined ? <Button onClick={() => this.props.history.push({ pathname: '/login', state: { getinfo: () => { this.getinfo() } } })} style={{ height: "68px", width: "60px", fontSize: "18px", color: "#fff", }} type="link">登录</Button> :
                                <img style={{ width: "45px", height: "45px", borderRadius: "10px", marginTop: "11px", opacity: "1", zIndex: "1000", cursor: "pointer", }} onClick={() => this.goPage("/UserInfo")} src={global.FaceURL != "" ? global.FaceURL : c1}></img>}
                        </div>
                    </div>
                </div>
                <div >{this.props.children}</div>
                <Modal
                    destroyOnClose={true}
                    centered={true}
                    title={null}
                    footer={null}
                    modalRender={(modal) => (
                        modal
                    )}
                    visible={this.state.MessageVisible}
                    onCancel={() => { this.setState({ MessageVisible: false }) }}
                    closable={true}
                    width={348}
                >
                    <div style={{ height: "413px", padding: "43px 34px", background: "#fff", borderRadius: "6px" }}>
                        <Empty description="您没有此模块的权限。" />
                    </div>
                </Modal>
            </div>
        )
    }
    componentDidMount() {
        if (cookie.load("token") != undefined || cookie.load("token")=='') {
            this.getinfo()
        }else {
            this.props.history.push("/login")
        }
    }
    isActive(item) {
        // if (item.name === active) {
        //     return true
        // }
        // return false
        //
        if (item.name === "校区运营") {
            var ss = this.props.history.location.pathname.split("/")
            if (ss.length > 2) {
                if (ss[1] == "operate") {
                    return true
                }
            }
        }
        if (item.path === this.props.history.location.pathname) {
            return true
        }
        return false
    }
    // renderActive() {
    //     var nav_name = cookie.load("nav_name").Name
    //     console.log(nav_name)
    //    
    //     return false
    // }
    goPage(Path) {
        if (cookie.load("token") != undefined) {
            this.props.history.push(Path,)
        }
    }
    changeTitle(item) {
        if (cookie.load("token") == undefined) {
            this.props.history.push({ pathname: '/login', state: { getinfo: () => { this.getinfo() } } })
        } else {
            if (global.Identity == 1 && (item.id == 11 || item.id == 13|| item.id == 15)) {
                this.setState({ MessageVisible: true })
                return
            }
            if (global.IsLive == 2 && item.id == 5 ) {
                this.setState({ MessageVisible: true })
                return
            }
            if (item.id == 15) {
                this.props.history.push('/operate/campusOperations')
            }else {
                this.props.history.push(item.path)
            }
        }

    }
    getinfo() {
        // service.request({
        //     method: 'get',
        //     url: service.api.homeURL + '/getinfo',
        //     params: {
        //     }
        // }).then(res => {
        //     if (res.code === 200) {
        //         this.setState({
        //             userInfo: res.data,
        //             FaceURL: res.data.FaceURL != "" ? (this.state.header + res.data.FaceURL) : '',
        //         })
        //     } else {
        //     }
        // })
    }
}
export default withRouter(Index)