import React, { Component } from 'react'
import { CloudDownloadOutlined, } from '@ant-design/icons';
import { Button, message, Empty, Modal, Input, Tooltip } from 'antd';
import service from '../../utlis/request'
import logo from "../../image/logo_2.png"
import live from "../../image/live.png"
export default class BroadcastList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // PhaseList: [{ Name: "启迪篇", ID: 1, }, { Name: "基础篇", ID: 2, }, { Name: "工具课程", ID: 3, }, { Name: "特色课程", ID: 4, }],
            List: [{}],
            header: 'https://api2.yuexiaojing.com/v3/',
        }

    }
    ////////////////////////////////////////////////////////////////////////
    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "20px", }}>
                <div style={{ display: "flex", flexWrap: " wrap", width: "1200px", margin: "auto" }}>
                    {
                        this.state.List.map((item, index) => {
                            return <div className='liveContent' key={index} style={{ height: "235px", background: "#fff", padding: "10px", borderRadius: "6px", margin: "10px" }}>
                                <div style={{ position: "relative", cursor: "pointer", }} onClick={() => this.open(item)}>
                                    <div style={{ height: "140px", width: "260px", borderRadius: "6px", background: "#fff", overflow: "hidden" }}>
                                        <img style={{ height: "140px", width: "260px", borderRadius: "6px", }} src={this.state.header + item.Cover} />
                                    </div>
                                    {item.State == 2 ?
                                        <div style={{ position: "absolute", top: "0px", right: "5px", background: "#ccc", display: "flex", borderRadius: "10px", padding: "0px 5px", marginLeft: "5px" }}>
                                            <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "red", margin: "7px 5px", }}></div>
                                            <div style={{ color: "#fff" }}>LIVE</div>
                                        </div>
                                        :
                                        <div style={{ color: "red", position: "absolute", top: "0px", right: "5px", display: "flex" }}>
                                            <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "#999999", margin: "7px 7px", }}></div>
                                            <div style={{ fontWeight: "700", color: "#999999" }}>LIVE</div>
                                        </div>}
                                </div>
                                <div style={{ background: "#fff", margin: "5px", display: "flex" }}>
                                    <img style={{ height: "40px", width: "40px", borderRadius: "50%", margin: "15px 10px" }} src={logo} />
                                    <div style={{ marginTop: "10px" }}>
                                        <div style={{ fontSize: "18px", color: "#000", }}>{item.Name}</div>
                                        <div style={{ marginTop: "5px", fontSize: "16px", color: "#999999", }}>悦小鲸官方账号</div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.getLiveRoom()
    }
    getLiveRoom() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/liveRoom',
            params: {
                offset: 0,
                limit: 0,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({ List: res.data })
            } else {
                message.error("系统繁忙，请重试")
            }
        })
    }
    open(item) {
        if (item.State == 2) {
        this.props.setPage(2, item)
        }
    }
}