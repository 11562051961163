import React, { Component } from 'react'

import cookie from "react-cookies";
import { message, Empty } from 'antd';
import OrderProduct from "./order_page/order_productPage"
import OrderDetails from "./order_page/order_details"
import VerifyOrder from "./order_page/verifyOrder"
import OrderPay from "./order_page/orderPay"

export default class Order extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Page: 1,
            CommodityItem: {},
            VerifyOrderItem: {},
            nav_arr: [],
            OrderInfoItem:{},
        }
    }
    ////////////////////////////////////////////////////////////////////////
    componentDidMount() {
    }
    render() {
        return (
            <div className='courseContent' style={{minWidth: "1300px"}} >
                <div style={{ width: "1300px" }}>
                    {this.state.Page==1?<OrderProduct  openDetails={(item)=>this.openDetails(item)} setPage={(page,)=>this.setPage(page)}></OrderProduct>:''}
                    {this.state.Page==2?<OrderDetails  VerifyOrderItem={(item)=>this.VerifyOrderItem(item)} CommodityItem={this.state.CommodityItem}  setPage={(page,)=>this.setPage(page)}></OrderDetails>:''}
                    {this.state.Page==3?<VerifyOrder  history={this.props.history} getOrderInfo={(item) => this.getOrderInfo(item)} VerifyOrderItem={this.state.VerifyOrderItem}  setPage={(page,)=>this.setPage(page)}></VerifyOrder>:''}
                    {this.state.Page==4?<OrderPay  history={this.props.history} OrderInfoItem={this.state.OrderInfoItem}  setPage={(page,)=>this.setPage(page)}></OrderPay>:''}

                </div>
            </div>
            // <div className='courseContent' >
            //     <div style={{ width: "1200px", }}>
            //         <div style={{ height: "calc(100vh - 98px)", padding: "200px 34px", background: "#fff", borderRadius: "6px" }}>
            //             <Empty description="暂无数据" />
            //         </div>
            //     </div>
            // </div>
        )
    }
    setPage(page, arr) {
        this.setState({ Page: page, })
    }
    openDetails(item) {
        this.setState({ CommodityItem: item })
    }
    VerifyOrderItem(item) {
        this.setState({ VerifyOrderItem: item })
    }
    getOrderInfo(item) {
        this.setState({
            OrderInfoItem:item
        })
    }
}