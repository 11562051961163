import React, { Component } from 'react'
import { Button, message, Empty, Modal, Input, Tooltip } from 'antd';
import service from '../../utlis/request'
export default class OrderProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: '',
            CommodityList: [],
            ParentID: 0,
            nav_arr: [],
            TableItem: {},
            header: 'https://api2.yuexiaojing.com/v3/'
        }

    }
    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                <div style={{ width: "1200px", height: "100%", marginTop: "15px", display: "flex", flexWrap: "wrap", margin: "auto" }}>
                    {
                        this.state.CommodityList.map((item, index) => {
                            return <div className='liveContent' key={index} onClick={() => this.switchCommodity(item)} index={index} style={{ width: "280px", height: "225px", padding: "20px", borderRadius: "8px", cursor: "pointer", fontSize: "25px", background: "#fff", margin: "0px 10px 20px 10px" }}>
                                <div style={{ height: "140px", width: "260px", borderRadius: "6px", background: "#fff", overflow: "hidden" }}>
                                    <img style={{ height: "135px", width: "240px", borderRadius: "6px", }} src={this.state.header + item.Cover} />
                                </div>
                                <div style={{ height: "30px", lineHeight: "30px", textAlign: "center", fontSize: "18px", color: "#000" }}>{item.Name ? (item.Name.length > 10 ? item.Name.substring(0, 10) + "..." : item.Name) : ""}</div>
                                <div style={{ height: "30px", lineHeight: "30px", fontSize: "20px", display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ color: "red" }}>{"￥" + item.Price / 100}</div>
                                    <div style={{ fontSize: "12px", color: "#8C8C8C" }}>{"库存：" + item.Inventory}</div>

                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.getCommodity()
    }
    getCommodity() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/commodity',
            params: {
                offset: 0,
                limit: 0,
                category: 1,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    CommodityList: res.data
                })
            } else {
            }
        })
    }
    switchCommodity(item) {
        this.props.setPage(2)
        this.props.openDetails(item)
    }
}