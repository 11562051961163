import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom'
import { mainRoutes } from './routes'
import App from './App'
import reportWebVitals from './reportWebVitals';
import service from './utlis/request';
import cookie from "react-cookies";
//
global.DownloadPhase = []
global.ViewPhase = []
global.Identity = 1
global.IsLive = 1
global.Watermark = "悦小鲸"

global.NickName = "NickName"
global.FaceURL = ""

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<Router>
//   <Route render={routeProps => <App {...routeProps} />} />
//   {mainRoutes.map(route => {
//     return <Route key={route.path} {...route} />
//   })}
// {/* <Redirect to="/homePage" /> */}
// </Router>);

//
if (cookie.load("token")) {
  service.request({
    method: 'get',
    url: service.api.homeURL + '/getpermission',
    params: {
    }
  }).then(res => {
    if (res.code == 200) {
      global.DownloadPhase = res.data.DownloadPhase
      global.ViewPhase = res.data.ViewPhase
      global.Identity = res.data.Identity
      global.IsLive = res.data.IsLive
      global.Watermark = res.data.Watermark

      global.NickName = res.data.Watermark + "--" + res.data.NickName
      if (res.data.FaceURL != "") {
        global.FaceURL = "https://api2.yuexiaojing.com/v3/" + res.data.FaceURL
      }
      //
      root.render(<Router>
        <Route render={routeProps => <App {...routeProps} />} />
        {mainRoutes.map(route => {
          return <Route key={route.path} {...route} />
        })}
        {/* <Redirect to="/homePage" /> */}
      </Router>);
    }
  }).catch(function (error) {
    console.log("catch", error);
  });
} else {
  root.render(<Router>
    <Route render={routeProps => <App {...routeProps} />} />
    {mainRoutes.map(route => {
      return <Route key={route.path} {...route} />
    })}
    {/* <Redirect to="/homePage" /> */}
  </Router>);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
