import React, { Component } from 'react'
// import * as styled from '../utlis/styled'
import s1 from "../../image/s1.png";
import s2 from "../../image/s2.png";
import service from '../../utlis/request';
import { Button, Carousel, message } from 'antd';
import CampusOperations_course from './campusOperations_page/campusOperations_course'
import CampusOperations_file from './campusOperations_page/campusOperations_file'
export default class CampusOperations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Page: 1,
            nav_arr: [],
        }
    }
    ////////////////////////////////////////////////////////////////////////
    componentDidMount() {
    }
    render() {
        return (
            <div className='courseContent' style={{ paddingTop: "68px" }}>
                <div style={{ width: "1365px", display: 'flex', }}>
                    <div style={{ width: "165px", display: 'flex', flexDirection: "column", borderLeft: "1px solid rgb(116 116 116 / 50%)" }}>
                        <div style={{ padding: "108px 40px 60px 0px", position: "relative", left: "-11px", display: "flex", alignItems: "center" ,cursor: "pointer"}}>
                            <img src={s2} style={{ width: "20px", height: "20px" }}></img>
                            <div style={{ fontSize: "20px", color: "#46b4db", paddingLeft: "20px" }}>校区运营</div>
                        </div>
                        <div onClick={()=>{ this.props.history.push('/operate/activity')}} style={{ padding: "0px 40px 0px 0px", position: "relative", left: "-9px", display: "flex", alignItems: "center",cursor: "pointer" }}>
                            <img src={s1} style={{ width: "16px", height: "16px" }}></img>
                            <div style={{ fontSize: "18px", paddingLeft: "20px" }}>全年活动</div>
                        </div>
                    </div>
                    {this.state.Page != 999 ? <CampusOperations_course nav_arr={this.state.nav_arr} setPage={(page, arr,) => this.setPage(page, arr)}></CampusOperations_course> : ''}
                    {this.state.Page == 999 ? <CampusOperations_file nav_arr={this.state.nav_arr} setPage={(page, arr,) => this.setPage(page, arr)}></CampusOperations_file> : ''}
                </div>
            </div>
        )
    }
    setPage(page, arr) {
        this.setState({ Page: page, nav_arr: arr }, () => { })
    }

}