import React, { Component } from 'react'
import { Button, message, Empty, Modal, Input, Tooltip } from 'antd';
import "../../index.css"
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
export default class OrderDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TableItem: {},
            header: 'https://api2.yuexiaojing.com/v3/',
            Number: 1,
        }

    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                <div style={{ width: "100%", height: "520px", marginTop: "15px", background: "rgba(255, 255, 255, 0.6)", borderRadius: "10px", padding: "30px 60px 51px 39px", }}>
                    <div style={{ height: "38px", margin: "0px 0 20px 20px" }} onClick={() => { this.props.setPage(1) }}>
                        <Button style={{ fontSize: "16px", color: "#fff", height: "38px", background: "#8fdde9", cursor: "pointer", }}>返回</Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "flex-end" }}>
                        <div style={{ marginLeft: "80px" }}>
                            <img style={{ width: "315px", height: "378px", borderRadius: "10px", }} src={this.state.header + this.props.CommodityItem.Cover}></img>
                        </div>
                        <div style={{}}>
                            <div style={{ fontSize: "30px", color: "#2FADCE", height: "50px", }}>{this.props.CommodityItem.Name}</div>
                            <div style={{ fontSize: "30px", color: "#2FADCE", display: "flex", height: "80px", lineHeight: "80px" }}>
                                <div style={{ width: "40px", fontSize: "30px", color: "#8C8C8C", fontSize: "18px", textAlign: "left" }}>价格</div>
                                <div style={{ color: "#ff5245", fontSize: "34px", marginLeft: "26px" }}>{"￥" + this.props.CommodityItem.Price / 100}</div>
                            </div>
                            <div style={{ fontSize: "18px", color: "#8C8C8C", fontSize: "18px", display: "flex", height: "80px", lineHeight: "80px", marginBottom: "15px" }}>
                                <div style={{ width: "40px", textAlign: "left" }}>库存</div>
                                <div style={{ fontSize: "16px", marginLeft: "26px", color: "#000" }}>{"剩余" + this.props.CommodityItem.Inventory + "份"}</div>
                            </div>
                            <div style={{ fontSize: "18px", color: "#8C8C8C", fontSize: "18px", display: "flex", height: "80px", marginBottom: "10px" }}>
                                <div style={{ flex: "2.2", textAlign: "left" }}>简介</div>
                                <div className='NodeText' style={{ flex: "19", fontSize: "16px", color: "#000" }}>{this.props.CommodityItem.Note}</div>
                            </div>
                            <div style={{ fontSize: "18px", color: "#8C8C8C", fontSize: "18px", display: "flex", marginBottom: "15px" }}>
                                <div style={{ width: "40px", textAlign: "right", lineHeight: "40px" }}>数量</div>
                                <div style={{ fontSize: "16px", marginLeft: "26px", display: "flex" }}>
                                    <button style={{ width: "32px", height: "32px", marginTop: "3px" }} onClick={() => { this.setState({ Number: this.state.Number > 1 ? this.state.Number - 1 : 1 }) }} type="button"><MinusOutlined /></button>
                                    <Input placeholder="数量" style={{ width: "100px", height: "32px", margin: "3px 10px 0px 10px" }} value={this.state.Number} onChange={(e) => {
                                        var reg = /^[0-9]*$/;//正则表达式
                                        var isNumber = new RegExp(reg)
                                        if (isNumber.test(e.target.value)) {
                                            this.setState({ Number: e.target.value },)
                                        }
                                    }} />
                                    <button style={{ width: "32px", height: "32px", marginTop: "3px" }} onClick={() => { this.setState({ Number: this.state.Number + 1 }) }} type="button"><PlusOutlined /></button>
                                    {/* <Button style={{ width: "135px", height: "41px", background: "#FD5F2B", color: "#fff", marginLeft: "260px" }} onClick={() => { this.buyNow() }}>立即购买</Button> */}
                                    <div className='button_r' style={{ width: "120px", height: "41px", color: "#fff", marginLeft: "260px", textAlignLast: "center", lineHeight: "40px", cursor: "pointer",fontSize:"16px" }} onClick={() => { this.buyNow() }}>立即购买</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount() {
    }
    buyNow() {
        var items = {
            item: this.props.CommodityItem,
            Number: this.state.Number
        }
        this.props.VerifyOrderItem(items)
        this.props.setPage(3)
    }
}