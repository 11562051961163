import axios from "axios";
import cookie from "react-cookies";
// const TOKEN_KEY = 'AS_MALL_ACCESS_TOKEN';

// 设置默认请求头token
// axios.defaults.headers.common['Authorization'] = localStorage.getItem(TOKEN_KEY);

// 创建实例
const service = axios.create({
    // baseURL: 'http://10.18.1.200:8095/home',
})



// 请求拦截
service.interceptors.request.use(
    function (config) {
        // cookie.load("token")
        // let token = localStorage.getItem("TOKEN_KEY");
        // console.log("获取token："+token);
        config.headers.Token = cookie.load("token");
        return config;
    }, function (error) {
        return Promise.reject(error);
    }
)

service.interceptors.response.use(
    function (response) {

        // if (Array.isArray(response.data.data)&&response.data.data.length==0){
        //     Helper.flash.error("暂无数据!")
        // }
        // 响应数据处理
    
       if (response.data.code === 499) {
            cookie.remove("token")
            // window.location.href = 'http://beta.yuexiaojing.com/#/'
            window.location.reload()
            return
        }
        return response.data;
    }, function (error) {

        // console.log("响应错误：");
        // console.log(error);
        // 响应错误处理
        return Promise.reject(error);
    }
);

service.api = {
    // homeURL: 'http://127.0.0.1:8097/general',
    homeURL: "https://api2.yuexiaojing.com/v3/general",
    liveURL: "api2.yuexiaojing.com/v3",
    // liveURL: "127.0.0.1:8097",
}
export default service;