import React, { Component } from 'react'
import s1 from "../../image/s1.png";
import s2 from "../../image/s2.png";
import Activity_course from "./activity_page/activity_course"
import Activity_class from "./activity_page/activity_class"
import Activity_file from "./activity_page/activity_file"
export default class Activity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Page: 1,
            CourseItem: {},
            ClassItem: {},
            nav_arr: []
        }
    }
    ////////////////////////////////////////////////////////////////////////
    componentDidMount() {
    }
    render() {
        return (
            <div className='courseContent' style={{ paddingTop: "68px" }}>
                <div style={{ width: "1365px", display: 'flex' }}>
                    <div style={{ width: "165px", display: 'flex', flexDirection: "column", borderLeft: "1px solid rgb(116 116 116 / 50%)" }}>
                        <div onClick={() => { this.props.history.push('/operate/campusOperations') }} style={{ padding: "108px 40px 60px 0px", position: "relative", left: "-9px", display: "flex", alignItems: "center", cursor: "pointer" }}>
                            <img src={s1} style={{ width: "16px", height: "16px" }}></img>
                            <div style={{ fontSize: "18px", paddingLeft: "20px" }}>校区运营</div>
                        </div>
                        <div style={{ padding: "0px 40px 0px 0px", position: "relative", left: "-11px", display: "flex", alignItems: "center", cursor: "pointer" }}>
                            <img src={s2} style={{ width: "20px", height: "20px" }}></img>
                            <div style={{ fontSize: "20px", color: "#46b4db", paddingLeft: "20px" }}>全年活动</div>
                        </div>
                    </div>
                    {this.state.Page == 1 ? <Activity_course nav_arr={this.state.nav_arr} setCourse={(item) => this.setCourse(item)} setPage={(page, arr,) => this.setPage(page, arr)}></Activity_course> : ''}
                    {this.state.Page == 2 ? <Activity_class CourseItem={this.state.CourseItem} nav_arr={this.state.nav_arr} setClass={(item) => this.setClass(item)} setPage={(page, arr,) => this.setPage(page, arr)}></Activity_class> : ''}
                    {this.state.Page == 3 ? <Activity_file ClassItem={this.state.ClassItem} nav_arr={this.state.nav_arr} setPage={(page, arr,) => this.setPage(page, arr)}></Activity_file> : ''}
                </div>
            </div>
        )
    }
    setPage(page, arr) {
        this.setState({ Page: page, nav_arr: arr })
    }
    setCourse(item) {
        this.setState({ CourseItem: item })
    }
    setClass(item) {
        this.setState({ ClassItem: item })
    }
}