import React, { Component } from 'react'
import { Button, Empty, Input, message } from 'antd';
import logo from "../../image/logo_2.png"
import TCPlayer from 'tcplayer.js';
import TcplayerBarragePlugin from 'tcplayer-barrage-plugin';
import 'tcplayer.js/dist/tcplayer.min.css';
import service from '../../utlis/request'
import cookie from "react-cookies";

const { TextArea } = Input;

export default class BroadcastHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newData: {},
            List: [{ Tilite: "直播珂", Intro: "简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介", ID: 1, IsNot: 1, }],
            url: "",
            header: 'https://api2.yuexiaojing.com/v3/',
            Discussions: [],
            content: '',
        }

    }
    ////////////////////////////////////////////////////////////////////////
    render() {

        return (
            <div style={{ marginTop: "0px", paddingTop: "20px", position: "relative" }}>
                <div style={{ marginBottom: "15px", height: "38px" }} onClick={() => { this.props.setPage(1) }}>
                    <Button style={{ fontSize: "16px", color: "#fff", height: "38px", background: "#8fdde9", cursor: "pointer", }}>返回</Button>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <video id="player-container-id" width="1000px" height="700px" preload="auto" playsinline webkit-playsinline>
                        </video>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div onClick={() => { this.setState({ SetBox: 2 }) }} style={{ cursor: "pointer", zIndex: "100", background: "rgb(207 242 247 / 100%)", height: "120px", width: "350px", borderRadius: "10px", display: "flex", marginBottom: "30px" }} >
                            <img style={{ height: "70px", width: "70px", borderRadius: "50%", margin: "20px 10px" }} src={logo} />
                            <div style={{ marginTop: "22px", marginLeft: "10px" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ fontSize: "24px", color: "#000", }}>{this.state.newData.Name}</div>
                                    {/* <div style={{ background: "#ccc", display: "flex", borderRadius: "10px", padding: "0px 5px", marginLeft: "5px" }}>
                                        <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "red", margin: "7px 5px", }}></div>
                                        <div style={{ color: "#fff" }}>LIVE</div>
                                    </div> */}
                                </div>
                                <div style={{ marginTop: "10px", fontSize: "18px", color: "#999999", }}>悦小鲸</div>
                            </div>
                        </div>
                        {/* <div style={{ fontSize: "16px", width: "350px", height: "120px", background: "rgb(255 255 255 / 64%)", marginBottom: "30px", borderRadius: "10px", padding: "10px 20px 0 20px" }}>简介
                            <div style={{ color: "#737373" }}>{this.state.newData.Note}</div>
                        </div>
                        <div style={{ width: "350px", height: "400px", background: "rgb(255 255 255 / 64%)", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "center" }}><Empty style={{ margin: 'auto' }} description="暂无推荐" /></div> */}
                        <div style={{ width: "350px", height: "550px", background: "#f6f7f8", borderRadius: "10px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <div id="discussion" style={{ width: "350px", height: "450px", margin: "10px 0 10px 0", overflow: "auto", }}>
                                {this.state.Discussions.map((v, index) => {
                                    return <div key={index} style={{ width: "320px", fontSize: "14px", padding: "5px", margin: "auto", }}>
                                        <div style={{ display: "inline", color: "#9499a0" }}>{v.Username + "："}</div>
                                        <span style={{ color: "#61666D" }}>{v.Content}</span>
                                    </div>
                                })}
                            </div>
                            <div style={{ width: "350px", height: "55px", display: "flex" }}>
                                <TextArea style={{ width: "330px", margin: 'auto' }} rows={2} placeholder="请输入提问,最多不超过30个字" maxLength={30} autoSize={{ minRows: 2, maxRows: 2 }} value={this.state.content} onChange={(e) => { this.setState({ content: e.target.value }) }} />
                            </div>
                            <div style={{ width: "350px", height: "45px", position: "relative" }}>
                                <Button type="primary" style={{ fontSize: "12px", height: "24px", width: "80px", position: "absolute", right: "10px", top: "5px", background: "#23ade5" }} onClick={() => { this.sendDiscussion() }}>发送</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount() {
        //
        this.setState({
            newData: this.props.Item,
            url: "webrtc://" + this.props.Item.PullURL + "/" + this.props.Item.AppName + "/" + this.props.Item.StreamName
        }, () => {
            this.player = TCPlayer('player-container-id', {
                sources: [{
                    src: this.state.url,
                }],
                licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307906966_1/v_cube.license',
                plugins: {
                    DynamicWatermark: { //
                        type: 'text',
                        speed: 0.2,// 建议取值范围 0<= speed <=1，默认值 0.2
                        content: '悦小鲸在线直播', // 类型必须为String，
                        opacity: 0.5,
                        fontSize: '16px', // type === text 时有效, 其余字段通用
                        color: '#bdbdbd',
                        // left: '2%',
                        // top: '2%',
                        // right: '',
                        // bottom: '',

                        // type: 'image',
                        // content: 'https://tcplayer-1306264703.cos.ap-nanjing.myqcloud.com/picture/icon-vcube.png',
                        // width: '200px', // type === image 时有效, 其余字段通用
                        // height: 'auto', // type === image 时有效, 其余字段通用
                    }
                }
            });
            // player-container-id 为播放器容器 ID，必须与 html 中一致
            // this.player.src(this.state.url); // url 播放地址
            //弹幕
            this.tcplayerBarrage = new TcplayerBarragePlugin(this.player);
            this.tcplayerBarrage.init();
            this.tcplayerBarrage.start();
        })
        //
        this.connect()
    }
    componentWillUnmount() {
        this.ws.close()
        if (this.player) {
            this.player.dispose();
        }
    }
    //
    scrollBottom() {
        let discussion = document.getElementById('discussion');
        discussion.scrollTop = discussion.scrollHeight;
    }
    addDiscussion(mess) {
        this.state.Discussions.push({ Content: mess.Title, Username: mess.Username, Time: mess.Time, UserID: mess.UserID })
        this.setState({}, () => { this.scrollBottom() })
        //
        var barrage = {
            "mode": 1,
            "text": mess.Title,
            "size": 25,
            "color": '#ff0000',
        };
        this.tcplayerBarrage.send(barrage);
    }
    //
    sendDiscussion() {
        if (this.state.content == "") {
            message.warning("提问不能为空")
            return
        }
        var mess = {
            EventType: 10,
            Title: this.state.content,
            Username: global.NickName,
            Time: new Date().getTime() * 1000000,
            // UserID: this.state.userinfo.ID,
        }
        this.ws.send(JSON.stringify(mess))
        this.setState({ content: '' })
    }
    //
    connect() {
        let token = cookie.load("token")
        if (token) {
            var c = 'wss://' + service.api.liveURL + '/live/join?roomID=' + this.props.Item.ID + '&token=' + token
            this.ws = new WebSocket(c)
            this.ws.onopen = () => {
                //
            }
            this.ws.onmessage = (e) => {
                var mess = JSON.parse(e.data)
                switch (mess.EventType) {
                    case 10:
                        this.addDiscussion(mess)
                        return
                    case 89:
                        //在线人数
                        return
                    case 99:
                        message.info(mess.Title)
                        // this.props.history.goBack()
                        // this.props.history.push('/teaching')
                        break
                    case -1:
                        message.error(mess.Title)
                        // this.props.history.goBack()
                        // this.props.history.push('/teaching')
                        break
                }
            }
            this.ws.onclose = (e) => {
            }
        }
    }
}