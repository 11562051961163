import React, { Component } from 'react'
// import * as styled from '../utlis/styled'
import cookie from "react-cookies";
import service from '../utlis/request';
import { Modal, message, Input, Button } from 'antd';
import moment from 'moment';
import { Fullscreen, SoundOutlined, RightOutlined } from '@ant-design/icons';
export default class Notice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      SearchName: "",
      NoticeList: [],
      NoticeVisible: false,
      Noticeitem: {},
    }
  }

  ////////////////////////////////////////////////////////////////////////
  render() {
    return (
      <div className='courseContent' >
        <div style={{ width: "1200px", justifyContent: 'center', marginTop: "80px" }}>
          <div style={{ fontSize: "25px", color: "#239bc6", display: "flex", justifyContent: "flex-end", marginBottom: "15px" }} >
            <Input placeholder="搜索" style={{ width: "300px", height: "40px", borderRadius: "8px 0px 0px 8px", border: "none" }} value={this.state.SearchName} onChange={(e) => { this.setState({ SearchName: e.target.value }) }} />
            <Button style={{ borderRadius: "0px 8px 8px 0px", height: "40px", background: "#47b3d8", color: "#fff" }} onClick={() => this.onSearch()}>搜索</Button>
          </div>
          {
            this.state.NoticeList.map((item, index) => {
              return <div style={{ width: "100%", height: "138px", display: "flex", marginBottom: "20px" }}>
                {/* <div style={{ width: "14px", height: "14px", borderRadius: "100%", background: "#d64747", marginTop: "71px", marginRight: "30px" }}></div> */}
                <div key={index} index={index} style={{ width: "1200px", height: "138px", fontSize: "20px", color: "#239bc6", display: "flex", padding: "30px 30px 26px 30px", boxSizing: "border-box", borderRadius: "10px", border: "1px solid #239bc6", }}>
                  <div style={{ width: "80px", height: "80px", background: "#239bc6", padding: "10px", borderRadius: "6px" }}><SoundOutlined style={{ color: "#fff", fontSize: "60px" }} /></div>
                  <div style={{ marginLeft: "15px", width: "85%" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ height: "30px", lineHeight: "30px", fontSize: "22px" }}>{item.Title}</div>
                      <div style={{ height: "30px", lineHeight: "30px", fontSize: "16px", color: "#718890" }}>{this.handleTime(item.Time)}</div>
                      {index == 0 ? <div style={{ width: "40px", height: "20px", lineHeight: "20px", fontSize: "14px", background: "red", borderRadius: "4px", color: "#fff", textAlign: "center", marginLeft: "20px", marginTop: "6px" }}>NEW</div> : <></>}
                    </div>
                    <div style={{ height: "30px", lineHeight: "30px", marginTop: "15px", fontSize: "18px", color: "#718890" }}>{item.Content ? (item.Content.length > 20 ? item.Content.substring(0, 20) + "..." : item.Content) : ""}</div>
                  </div>
                  <RightOutlined onClick={() => this.noticeDetails(item)} style={{ color: "#239bc6", fontSize: "34px", cursor: "pointer", marginTop: "23px" }} />
                </div>
                <Modal
                  destroyOnClose={true}
                  centered={true}
                  title={null}
                  footer={null}
                  modalRender={(modal) => (
                    modal
                  )}
                  // bodyStyle={{ padding: '0px' }}
                  visible={this.state.NoticeVisible}
                  onCancel={() => this.close()}
                  closable={true}
                  width={900}
                >
                  <div style={{ height: "700px", padding: "10px 20px", position: "relative" }}>
                    <h5 style={{ widht: "100%", height: "40px", fontSize: "25px", textAlign: "center", color: "#239bc6" }}>公告</h5>
                    <div style={{ widht: "100%", height: "40px", fontSize: "20px", color: "#239bc6" }}>{this.state.Noticeitem.Title}</div>
                    <div>
                      {this.state.Noticeitem.Content}
                    </div>
                    <div style={{ widht: "100%", height: "40px", fontSize: "18px", position: "absolute", right: "20px", bottom: "0px" }}>
                      {this.handleTime(this.state.Noticeitem.Time)}
                    </div>
                  </div>
                </Modal>
              </div>
            })
          }

        </div>
      </div>
    )
  }
  componentDidMount() {
    this.getNotice()
  }
  getNotice() {
    service.request({
      method: 'get',
      url: service.api.homeURL + '/notice',
      params: {
        offset: 0,
        limit: 0,
      }
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          NoticeList: res.data
        })
      } else {
      }
    })
  }
  onSearch() {
    service.request({
      method: 'get',
      url: service.api.homeURL + '/notice',
      params: {
        offset: 0,
        limit: 0,
        name: this.state.SearchName,
      }
    }).then(res => {
      if (res.code === 200) {
        this.setState({
          NoticeList: res.data
        }, () => {
          if (this.state.NoticeList.length <= 0) {
            message.error("没有此条数据")
          }
        })
      } else {
      }
    })
  }
  handleTime(time) {
    const duration = (Date.now() - time) / 1000
    // const duration = (+Date.now()*1000000 - time) 

    // return duration > 60
    //     ? `${Math.round(duration / 60)} 分钟前`
    //     : `${Math.round(Math.max(duration, 1))} 秒前`
    if (duration < 60) {
      return `${Math.round(Math.max(duration, 1))} 秒前`
    }
    else if (60 < duration && duration < 3600) {
      return `${Math.round(duration / 60)} 分钟前`
    }
    else if (3600 < duration && duration < 82800) {
      return `${Math.round(duration / 3600)} 小时前`
    }
    else if (duration > 82800) {
      // return moment(time / 1000000).format("YYYY-MM-DD")
      return moment(time).format("YYYY-MM-DD")
    }
  }
  noticeDetails(item) {
    this.setState({
      NoticeVisible: true,
      Noticeitem: item,
    })
  }
  close() {
    this.setState({
      NoticeVisible: false,
    })
  }
  // 鼠标事件
  enter(ID) {
    var doc = document.getElementById(ID)
    doc.style.background = "#bfecd7"
    doc.style.border = "2px solid #e25757"
    // e.target.style.background = "#bfecd7"
  }
  remove(ID) {
    var doc = document.getElementById(ID)
    doc.style.background = "#e8d2c6"
    doc.style.border = "1px solid #ccc"
    // e.target.style.background = 'none'
  }
}