import React, { Component } from 'react'
// import ReactFileReader from 'react-file-reader'
import * as styled from "../utlis/styled"
import cookie from "react-cookies";
import service from '../utlis/request';
import { Button, Upload, message, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import c2 from '../image/logo_blue.png';
import Address from "./userInfo_page/address"
import Courseware from "./userInfo_page/courseware"
import MyOrder from "./userInfo_page/myOrder"
export default class TrainingCourse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userInfo: { FaceURL: "", },
            header: 'https://api2.yuexiaojing.com/v3/',
            setUserInfo: [{ Name: "我的头像", ID: 2 }, { Name: "修改密码", ID: 3 }, { Name: "收货地址", ID: 4 }, { Name: "我的订单", ID: 5 }, { Name: "课件管理", ID: 6 },],
            setItemID: 2,
            // currentUserName: "",
            // newUserName: "",
            url: "",
            file: "",
            oldpassword: "",
            newpassword: "",
            repeatnewpassword: "",
        }
    }

    ////////////////////////////////////////////////////////////////////////
    render() {
        //处理头像
        var { url, showImg } = this.state;
        var imagePreview = null;
        if (url) {
            imagePreview = (<label for="avatarFor" >< img style={{ width: '153px', height: "153px", padding: "18px 0px " }} src={this.state.url} /></label>);
            showImg = 'none';
        } else {
            showImg = 'inline';
        }

        return (
            <div className='courseContent' >
                <div style={{ width: "1200px", display: "flex", margin: "40px 0px 40px 0px", background: "rgb(255 255 255 / 70%)", borderRadius: "6px" }}>
                    <div style={{ flex: "2", }}>
                        <div style={{ borderRight: "1px solid #c5d1d5", width: "100%", height: "100%", padding: "60px 0px", }}>
                            <div style={{ widht: "100%", height: "80px", display: "flex", justifyContent: 'center', }}>
                                <img style={{ widht: "80px", height: "80px", borderRadius: "10px", }} src={this.state.userInfo.FaceURL != "" ? this.state.header + this.state.userInfo.FaceURL : c2}></img>
                            </div>
                            <div style={{ width: "100%", height: "38px", lineHeight: "38px", textAlign: "center", color: "#0684b0", fontSize: "20px", marginTop: "33px", cursor: "pointer" }} onClick={() => this.logOut()}>退出登录</div>
                            <div style={{ width: "100%", marginTop: "30px" }}>
                                {
                                    this.state.setUserInfo.map((item, index) => {
                                        return <div onClick={() => { this.setState({ setItemID: item.ID }) }} style={{ width: "100%", height: "45px", lineHeight: "45px", textAlign: "center", color: this.state.setItemID == item.ID ? "#fff" : "#7AC4D9", fontSize: "20px", background: this.state.setItemID == item.ID ? "#7AC4D9" : "", cursor: "pointer" }}>{item.Name}</div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: "8", }}>
                        {this.state.setItemID == 2 ? <div>
                            <div style={{ width: "100%", height: "60px", padding: "10px 20px", borderBottom: "1px solid #EBEBEB", }}>
                                <div style={{ height: "40px", width: "100%", display: "flex", borderLeft: "6px solid #7AC4D9", lineHeight: "40px", fontSize: "18px", color: "#7AC4D9", paddingLeft: "20px" }}>
                                    我的头像
                                </div>
                            </div>
                            <styled.InfoRight style={{ flexDirection: 'row' }}>
                                <div style={{ flex: '1', fontWeight: 'bold', color: '#0684b0' }}>当前头像<img src={this.state.userInfo.FaceURL != "" ? (this.state.header + this.state.userInfo.FaceURL) : c2} alt="" style={{ width: '8.5rem', height: '8.5rem', padding: '1rem 0', display: 'block' }} /></div>
                                <div style={{ flex: '1', fontWeight: 'bold', position: "relative" }}>
                                    <div style={{ padding: "0px", color: '#0684b0' }}>
                                        修改头像
                                    </div>
                                    <input id="avatarFor" style={{ display: 'none' }} type="file" onChange={(e) => this.handleFiles(e)} />
                                    {imagePreview}
                                    <label style={{ color: "#0684b0", border: "1px dashed #2980b9", padding: '3px 10px ', marginLeft: "10px", display: showImg, width: "100px" }} for="avatarFor">点击上传图片</label>
                                    <p style={{ color: '#0684b0' }}>点击上方头像修改</p>
                                    <div style={{ padding: "0px" }}>
                                        <Button style={{
                                            width: "120px", height: "40px", lineHeight: "32px", color: "#fff", background: "#68c7e4", borderRadius: "12px", fontSize: "18px", border: "none"
                                        }} onClick={() => { this.editfaceurl() }}>保存</Button>
                                    </div>
                                </div>
                                <div style={{ flex: '1', fontWeight: 'bold', color: '#0684b0' }}>头像预览
                                    <img src={this.state.url} alt="" style={{ width: '100px', height: '100px', display: 'block', }} /><p style={{ fontSize: '.65rem', color: '#999' }}>100*100效果</p>
                                    <img src={this.state.url} alt="" style={{ width: '80px', height: '80px', display: 'block', }} /><p style={{ fontSize: '.65rem', color: '#999' }}>80*80效果</p>
                                    <img src={this.state.url} alt="" style={{ width: '60px', height: '60px', display: 'block', }} /><p style={{ fontSize: '.65rem', color: '#999' }}>60*60效果</p>
                                </div>
                            </styled.InfoRight></div> : ""}
                        {this.state.setItemID == 3 ? <div>
                            <div style={{ width: "100%", height: "60px", padding: "10px 20px", borderBottom: "1px solid #EBEBEB", }}>
                                <div style={{ height: "40px", width: "100%", display: "flex", borderLeft: "6px solid #7AC4D9", lineHeight: "40px", fontSize: "18px", color: "#7AC4D9", paddingLeft: "20px" }}>
                                    修改密码
                                </div>
                            </div>
                            <div style={{ padding: "100px 200px", }}>

                                <div style={{ display: "flex", marginBottom: "20px" }}>
                                    <div style={{ width: "150px", fontSize: "20px", color: "#0684b0", textAlign: "right", height: "32px" }}>请输入旧密码:</div>
                                    <Input type='password' onChange={(e) => { this.setState({ oldpassword: e.target.value, }) }} value={this.state.oldpassword} style={{ border: "none", background: "#68c7e4", width: "218px", height: "36px", lineHeight: "36px", fontSize: "20px", color: "#fff", marginLeft: "10px" }} />
                                </div>
                                <div style={{ display: "flex", marginBottom: "20px" }}>
                                    <div style={{ width: "150px", fontSize: "20px", color: "#0684b0", textAlign: "right", height: "32px" }}>请输入新密码:</div>
                                    <Input type='password' onChange={(e) => { this.setState({ newpassword: e.target.value, }) }} value={this.state.newpassword} style={{ border: "none", background: "#68c7e4", width: "218px", height: "36px", lineHeight: "36px", fontSize: "20px", color: "#fff", marginLeft: "10px" }} />
                                </div>
                                <div style={{ display: "flex", marginBottom: "20px" }}>
                                    <div style={{ width: "150px", fontSize: "20px", color: "#0684b0", textAlign: "right", height: "32px" }}>确认新密码:</div>
                                    <Input type='password' onChange={(e) => { this.setState({ repeatnewpassword: e.target.value, }) }} value={this.state.repeatnewpassword} style={{ border: "none", background: "#68c7e4", width: "218px", height: "36px", lineHeight: "36px", fontSize: "20px", color: "#fff", marginLeft: "10px" }} />
                                </div>
                                <Button onClick={() => this.editpassword()} style={{ width: "100px", height: "40px", lineHeight: "32px", color: "#fff", background: "#68c7e4", borderRadius: "12px", fontSize: "18px", marginLeft: "172px", marginTop: "20px" }}>保存</Button>
                            </div>
                        </div> : ""}
                        {this.state.setItemID == 4 ? <Address></Address> : ""}
                        {this.state.setItemID == 5 ? <MyOrder></MyOrder> : ""}
                        {this.state.setItemID == 6 ? <Courseware></Courseware> : ""}

                    </div>
                </div>
            </div>
        )
    }


    componentDidMount() {
        if (this.props.history.location.state != undefined) {
            this.setState({ setItemID: this.props.history.location.state })
        }
        this.getinfo()
    }
    handleFiles(e) {
        this.setState({
            url: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        })
    }
    UploadImage(f) {
        this.setState({ url: f.file })
    }

    beforeUpload() {
        return false
    }
    getinfo() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/getinfo',
            params: {
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    userInfo: res.data,
                    url: res.data.FaceURL != "" ? (this.state.header + res.data.FaceURL) : c2
                })
            } else {
            }
        })
    }
    logOut() {
        cookie.remove("token")
        global.NickName = "NickName"
        global.FaceURL = ""
        this.props.history.push("/",)
    }
    editfaceurl() {
        var form = new FormData
        form.set("File", this.state.file)
        service.request({
            url: service.api.homeURL + '/updateFaceURL',
            method: "put",
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: form,
        }).then(res => {
            if (res.code === 200) {
                message.success('修改成功');
                window.location.reload()
                // this.getinfo()
            } else {
                message.error("修改失败")
            }
        })
    }
    editpassword() {
        if (this.state.newpassword !== this.state.repeatnewpassword) {
            message.error('两次输入密码不匹配!');
            return
        }
        service.request({
            method: 'put',
            url: service.api.homeURL + '/updatePassword',
            data: {
                OldPassword: this.state.oldpassword,
                NewPassword: this.state.newpassword,
                ConfirmPassword: this.state.repeatnewpassword
            }
        }).then(res => {
            if (res.code === 200) {
                message.success('修改成功!');

                this.setState({
                    ldpassword: "",
                    newpassword: "",
                    repeatnewpassword: "",
                })
                window.location.reload()
                // this.getinfo()
                // this.props.history.push('/home')
            } else {
                if (res.code == 458) {
                    message.error('旧密码输入有误!');
                } else {
                    message.error('修改失败，请重试!');
                }

            }
        })
    }
    renderSetInfo() {
        switch (this.state.setItemID) {
            // case 1:
            //   return <div style={{ marignTop: "20px", }}>
            //     <p style={{ height: "40px", color: "#177390", lineHeight: "40px", fontWeight: "600", fontSize: "23px" }}>个人资料</p>
            //     <div style={{ display: "flex", marginBottom:"20px"}}>
            //       <div style={{ width: "120px", fontSize: "20px", color: "#0684b0", textAlign: "right",height:"32px" }}>当前用户名:</div>
            //       <Input onChange={(e) => { this.setState({ currentUserName: e.target.value, }) }} value={this.state.currentUserName} style={{ border: "none", background: "#68c7e4", width: "218px", height: "36px",lineHeight:"36px",fontSize:"20px",color:"#fff", marginLeft: "10px" }} />
            //     </div>
            //     <div style={{ display: "flex",marginBottom:"20px" }}>
            //       <div style={{ width: "120px", fontSize: "20px", color: "#0684b0", textAlign: "right",height:"32px" }}>新用户名:</div>
            //       <Input onChange={(e) => { this.setState({ newUserName: e.target.value, }) }} value={this.state.newUserName} style={{ border: "none", background: "#68c7e4", width: "218px", height: "36px",lineHeight:"36px",fontSize:"20px",color:"#fff",  marginLeft: "10px" }} />
            //     </div>
            //     <Button style={{width:"200px",height:"40px",lineHeight:"32px",color:"#fff",background:"#68c7e4",borderRadius:"12px",fontSize:"18px",marginLeft:"137px",marginTop:"20px"}}>保存</Button>
            //   </div>
            case 2:
                return
            case 3:
                return <div>密码</div>
        }
    }


}