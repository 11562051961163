import React, { Component } from 'react'
import { Button, message, Space, Modal, Radio, Spin } from 'antd';
import service from '../../utlis/request'
import "../../index.css"
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
export default class VerifyOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TableItem: {},
            List: [],
            IsDefaultItem: {},
            userInfo: {},
            editorAddressVisible: false,
            AddressID: 0,
            Loading: false,
            Amount: 0,
            header: 'https://api2.yuexiaojing.com/v3/',
        }

    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                <Spin spinning={this.state.Loading} style={{ position: "fixed", top: "20%" }} tip="加载中......" size="large">
                    <div style={{ width: "100%", height: "100%", marginTop: "15px", background: "rgba(255, 255, 255, 0.6)", borderRadius: "10px", padding: "51px 30px 51px 30px", fontSize: "14px" }}>
                        <p style={{ height: "60px", fontSize: "20px", textAlign: "center", fontWeight: "700", borderBottom: "1px solid #C2C2C2" }}>订单确认</p>
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                display: 'flex',
                                borderBottom: "1px solid #C2C2C2",
                                padding: "20px 0px",
                            }}
                        >
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>商品名称</div>
                                <div >{this.state.TableItem.Name}</div>
                            </Space>
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>购买数量</div>
                                <div>{this.props.VerifyOrderItem.Number}</div>
                            </Space>
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>单价</div>
                                <div style={{}}>{"￥" + this.state.TableItem.Price / 100}</div>
                            </Space>
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>买家账号</div>
                                <div>{this.state.userInfo.Username}</div>
                            </Space>
                            {this.state.List.length > 0 ? <Space align="" style={{ position: "relative", width: "100%" }}>
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>邮寄地址</div>
                                <div style={{}}>
                                    <div>{this.state.IsDefaultItem.People}&nbsp;&nbsp;{this.state.IsDefaultItem.Phone}</div>
                                    <div>{this.state.IsDefaultItem.Province}&nbsp;{this.state.IsDefaultItem.City}&nbsp;{this.state.IsDefaultItem.Area}&nbsp;{this.state.IsDefaultItem.Detailed}</div>
                                </div>
                                <Button onClick={() => this.setState({ editorAddressVisible: true })} style={{ position: "absolute", right: "0px", background: "#fff", color: "#000", borderRadius: "6px", fontSize: "14px", color: "#344563" }}>更换</Button>
                            </Space>
                                :
                                <Space align="" style={{ position: "relative", width: "100%" }}>
                                    <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>邮寄地址</div>
                                    <div style={{ color: "#C2C2C2" }}>
                                        暂无地址信息
                                    </div>
                                    <Button onClick={() => this.goPage()} style={{ position: "absolute", right: "0px", background: "#fff", color: "#000", borderRadius: "6px", fontSize: "14px", color: "#344563" }}>去添加</Button>
                                </Space>
                            }
                        </Space>
                        <Modal
                            destroyOnClose={true}
                            centered={true}
                            title={null}
                            footer={null}
                            modalRender={(modal) => (
                                modal
                            )}
                            // bodyStyle={{ padding: '0px' }}
                            open={this.state.editorAddressVisible}
                            onCancel={() => this.setState({ editorAddressVisible: false })}
                            closable={true}
                            width={700}
                        >
                            <div style={{ heighe: "100%", }}>
                                <div style={{ width: "100%", height: "40px", lineHeight: "40px", color: "#8291A9", fontSize: "20px", marginBottom: "20px" }}>地址选择</div>
                                <div >
                                    <Space
                                        direction="vertical"
                                        size="large"
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Space align="center">
                                            <Radio.Group onChange={(value) => { this.onChange(value) }} value={this.state.AddressID}>
                                                <Space direction="vertical">
                                                    {
                                                        this.state.List.map((item, index) => {
                                                            return < Radio value={item.ID} >
                                                                <div style={{ display: "flex" }}>
                                                                    <div>{item.People}&nbsp;{item.Phone}</div>
                                                                    {item.IsDefault == 1 ? <div style={{ position: "absolute", right: "-270px", color: "#8291A9", }}>默认地址</div> : ""}
                                                                </div>
                                                                <div style={{ marginBottom: "15px" }}>{item.Province}&nbsp;{item.City}&nbsp;{item.Area}&nbsp;{item.Detailed}</div>
                                                            </Radio>
                                                        })
                                                    }
                                                </Space>
                                            </Radio.Group>
                                        </Space>
                                    </Space>
                                </div>
                                <div style={{ marginTop: "20px", textAlign: "center" }}>
                                    <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => this.saveAddressID()}>确定</Button>
                                    <Button style={{ color: "#7AC4D9", }} onClick={() => this.setState({ editorAddressVisible: false })}>取消</Button>
                                </div>
                            </div>
                        </Modal >
                        <div style={{ display: "flex", height: "50px", lineHeight: "50px", justifyContent: "flex-end", marginBottom: "20px" }}>
                            <div style={{ color: "#8291A9" }}>实际支付</div>
                            <div style={{ fontSize: "24px", color: "#FA5151", marginLeft: "20px" }}>{this.props.VerifyOrderItem.Number * this.state.TableItem.Price / 100 + "￥"}</div>
                        </div>
                        <div style={{ fontSize: "18px", color: "#8C8C8C", fontSize: "18px", display: "flex", marginBottom: "15px", width: "100%", justifyContent: "flex-end" }}>
                            <Button onClick={() => this.goPay()} style={{ background: "#7AC4D9", border: "none", color: "#fff", }}>去支付</Button>
                            <Button onClick={() => this.props.setPage(2)} style={{ background: "#fff", color: "#000", marginLeft: "40px", }}>取消</Button>
                        </div>
                    </div >
                </Spin>
            </div >
        )
    }
    componentDidMount() {
        this.getAddress()
        this.getinfo()
        this.setState({
            TableItem: this.props.VerifyOrderItem.item,
            Amount: this.props.VerifyOrderItem.Number,
        })
    }
    getAddress() {
        service.request({
            url: service.api.homeURL + '/address',
            method: "get",
            params: {
                offset: 0,
                limit: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                if (res.data.length > 0) {
                    res.data.map((item, index) => {
                        if (item.IsDefault == 1) {
                            this.setState({ IsDefaultItem: item, AddressID: item.ID })
                        } else {
                            if (index == 0) {
                                this.setState({ IsDefaultItem: item, AddressID: item.ID })
                            }
                        }
                    })
                }
                this.setState({ List: res.data, })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getinfo() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/getinfo',
            params: {
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    userInfo: res.data,
                })
            } else {
            }
        })
    }
    onChange(e) {
        this.setState({ AddressID: e.target.value })
    }
    saveAddressID() {
        this.state.List.map((item, index) => {
            if (item.ID == this.state.AddressID) {
                this.setState({ IsDefaultItem: item, editorAddressVisible: false })
            }
        })
    }
    goPage() {
        this.props.history.push({ pathname: "/UserInfo", state: 4 })
    }
    goPay() {
        if (this.state.IsDefaultItem.Province && this.state.IsDefaultItem.City && this.state.IsDefaultItem.Area && this.state.IsDefaultItem.Detailed && this.state.IsDefaultItem.People && this.state.IsDefaultItem.Phone) {
            this.setState({ Loading: true })
            service.request({
                url: service.api.homeURL + '/order',
                method: "post",
                data: {
                    TargetID: this.state.TableItem.ID,
                    Amount: this.state.Amount,
                    Province: this.state.IsDefaultItem.Province,
                    City: this.state.IsDefaultItem.City,
                    Area: this.state.IsDefaultItem.Area,
                    Detailed: this.state.IsDefaultItem.Detailed,
                    People: this.state.IsDefaultItem.People,
                    Phone: this.state.IsDefaultItem.Phone,
                },
            }).then(res => {
                if (res.code == 200) {

                    this.props.getOrderInfo(res.data)
                    this.setState({ Loading: false })
                    message.success('订单生成成功');
                    this.props.setPage(4)
                } else {
                    this.setState({ Loading: false })
                    message.error('订单生成失败，请重试');
                }
            }).catch(function (error) {
                message.error('系统繁忙');
                console.log(error)
            })
        } else {
            message.error('请选择或添加地址');
        }

    }
}