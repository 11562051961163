import React, { Component } from 'react'
import { Button, message, Empty, Modal, Input, Tooltip } from 'antd';
import service from '../../../utlis/request'
export default class CourseSystem_class extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // PhaseList: [{ Name: "启迪篇", ID: 1, }, { Name: "基础篇", ID: 2, }, { Name: "工具课程", ID: 3, }, { Name: "特色课程", ID: 4, }],
            ClassList: [],
            ActivityID: 0,
            nav_arr: [],
            Name: "",
            header: 'https://api2.yuexiaojing.com/v3/'
        }

    }
    ////////////////////////////////////////////////////////////////////////
    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "40px", width: "1200px" }}>
                {this.state.nav_arr.length > 0 ? <div style={{ display: "flex", marginBottom: "15px", position: "relative" }}>
                    {
                        this.state.nav_arr.map((item, index) => {
                            if (index < 1) {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    {item.Name}
                                </div>
                            } else {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    <span>{">"}</span>
                                    {item.Name} </div>
                            }

                        })
                    }
                    <div style={{ position: "absolute", right: "20px", top: "0px", fontSize: "25px", color: "#239bc6", display: "flex", }} >
                        <Input placeholder="输入名称进行搜索" style={{ width: "300px", height: "33px", borderRadius: "8px 0px 0px 8px", border: "none" }} value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value },) }} />
                        <Button style={{ borderRadius: "0px 8px 8px 0px", height: "33px", background: "#47b3d8", color: "#fff" }} onClick={() => this.getClass()}>搜索</Button>
                    </div>
                </div> : ''}

                <div style={{ width: "100%", marginTop: "35px", display: "flex", flexWrap: "wrap", }}>
                    {
                        this.state.ClassList.map((item, index) => {
                            return <div className='liveContent' key={index} onClick={() => this.switchClass(item)} index={index} style={{ width: "280px", height: "225px", cursor: "pointer", fontSize: "18px", color: "#239bc6", background: "#fff", borderRadius: "10px", marginBottom: "30px", marginRight: "20px", padding: "20px" }}>
                                <img style={{ width: "240px", height: "135px", objectFit: "contain" }} src={this.state.header + item.Cover}></img>
                                <p style={{ height: "40px", lineHeight: "40px", textAlign: "center", fontSize: "20px" }}>{item.Name ? (item.Name.length > 10 ? item.Name.substring(0, 10) + "..." : item.Name) : ""}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.setState({ ActivityID: this.props.CourseItem.ID, nav_arr: this.props.nav_arr }, () => {
            this.getClass()
        })

    }
    getClass() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/train',
            params: {
                offset: 0,
                limit: 0,
                name: this.state.Name,
                activityID: this.state.ActivityID
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    ClassList: res.data,
                }, () => {
                    if (this.state.ClassList.length <= 0) {
                        message.error("该课程下无此条课时")
                    }
                })
            } else {
            }
        })
    }
    switchPage(index) {
        if (index == 0) {
            var arr = []
            arr.push(this.state.nav_arr[index])
            this.props.setPage(1, arr)
        }
    }
    switchClass(item) {
        this.props.setClass(item)
        this.state.nav_arr.push(item)
        this.setState({}, () => {
            this.props.setPage(3, this.state.nav_arr)

        })

    }

}