import { Route, Redirect } from 'react-router-dom'
import React from 'react';
//main
import Login from '../page/login';
import UserInfo from '../page/userInfo';
import Notice from '../page/Notice';
// 
//page
import HomePage from '../page/homePage';
import PayCourse from '../page/payCourse';

import Activity from '../page/onlineCourse/activity';
import TeacherTraining from '../page/onlineCourse/teacherTraining';
import CampusOperations from '../page/onlineCourse/campusOperations';
import CourseSystem from '../page/onlineCourse/courseSystem';
import Order from '../page/order';
import LiveBroadcast from '../page/liveBroadcast';







//main
export const mainRoutes = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/UserInfo',
        component: UserInfo
    },
    {
        path: '/Notice',
        component: Notice
    },

]



//home
export const pageRoutes = [
    {
        path: '/',
        isShow: true,
        exact: true,
        name: '首页',
        id: 1,
        component: HomePage,
    },
    {
        path: '/payCourse/courseSystem',
        isShow: true,
        exact: true,
        name: '教学课程',
        id: 14,
        component: CourseSystem
    },
    // {
    //     path: '/payCourse/teacherTraining',
    //     isShow: true,
    //     exact: true,
    //     name: '教师培训',
    //     id: 12,
    //     component: TeacherTraining,
    //     // isAuth: () => {
    //     //     if (global.Identity == 2) {
    //     //         return true
    //     //     }else {
    //     //         return false
    //     //     }
    //     // },
    // },
    {
        path: '/operate',
        isShow: true,
        exact: true,
        name: '校区运营',
        id: 15,
        children:[
            {
                path: '/operate/campusOperations',
                isShow: true,
                exact: true,
                name: '校区运营',
                id: 13,
                component: CampusOperations,
                isAuth: () => {
                    if (global.Identity == 2) {
                        return true
                    }else {
                        return false
                    }
                },
            },
            {
                path: '/operate/activity',
                isShow: true,
                exact: true,
                name: '全年活动',
                id: 11,
                component: Activity,
                isAuth: () => {
                    if (global.Identity == 2) {
                        return true
                    }else {
                        return false
                    }
                },
            },
        ],
    },
    {
        path: '/order',
        isShow: true,
        exact: true,
        name: '周边订购',
        id: 4,
        component: Order,
    },
    {
        path: '/liveBroadcast',
        isShow: true,
        exact: true,
        name: '在线直播',
        id: 5,
        component: LiveBroadcast,
        isAuth: () => {
            if (global.IsLive == 1) {
                return true
            }else {
                return false
            }
        },
    },
]


export const renderRoutesMain = (Routes) => {
    return Routes.map((route, index) => {
        return (
            <div key={index}>
                < Route
                    path={route.path}
                    isShow={route.isShow}
                    exact={route.exact}
                    render={props => {
                        if (route.isAuth) {
                            if (route.isAuth()) {
                                return <route.component {...props} />
                            }else {
                                return <Redirect to="/" />
                            }
                        } else {
                            return <route.component {...props} />
                        }
                    }}
                />

                {route.children && renderRoutesMain(route.children)}
            </div>
        )
    })
}