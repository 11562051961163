import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
// import dynamicWatermark from 'videojs-dynamic-watermark'
import { Watermark } from 'antd';

// videojs.registerPlugin('dynamicWatermark', dynamicWatermark);

export default class VideoPlayer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  // Instantiate a Video.js player when the component mounts
  componentDidMount() {
    // 监听页面全屏事件
    window.onresize = () => {
      // 全屏
      if (document.fullscreenElement) {
        this.setState({ isFullScreen: true });
      }
      // 不是全屏
      else {
        this.setState({ isFullScreen: false });
      }
    }
    //
    this.player = videojs(this.videoNode, {
      controlBar: {
        // 非IE浏览器隐藏全屏按钮
        fullscreenToggle: !!videojs.browser.IE_VERSION
      }, ...this.props
    }, () => {
      videojs.log('onPlayerReady', this);
    });
    //
    const btn = this.player.controlBar.addChild('button', {
      clickHandler: this.triggerFullScreen
    });
    btn.addClass('vjs-fullscreen-control');
    btn.addClass('vjs-control');
    btn.addClass('vjs-button');

    // this.player.dynamicWatermark({
    //   elementId: "unique_id",
    //   watermarkText: global.Watermark,
    //   changeDuration: 1000,
    //   cssText: "display: inline-block; color: grey; background-color: transparent; font-size: 1rem; z-index: 9999; position: absolute;",
    // })
  }

  triggerFullScreen() {
    var videoWatermark = document.querySelector('.videoWatermark')
    if (!this.state.isFullScreen) {
      videoWatermark.requestFullscreen();
      this.setState({ isFullScreen: true })
    } else {
      document.exitFullscreen();
      this.setState({ isFullScreen: false })
    }
  }
  // Dispose the player when the component will unmount
  componentWillUnmount() {
    // if (this.player) {
    //   this.player.dispose();
    // }
  }

  // Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
  // won't create additional wrapper in the DOM.
  //
  // See: https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <Watermark className="videoWatermark" style={{ width: "100%", height: "100%" }} content={global.Watermark} gap={[500,500]}>
        <div data-vjs-player style={{ width: "100%", height: "100%" }}>
          <video ref={node => this.videoNode = node} className="video-js" src={this.props.src}></video>
        </div>
      </Watermark>
    );
  }
}