import React, { Component } from 'react'
import cookie from "react-cookies";
import { Modal, Empty } from 'antd';

import yulu from '../image/yulu.png';
import whale1 from '../image/111.png';//师训
import whale2 from '../image/222.png';//课程
import whale3 from '../image/333.png';//全年
import whale4 from '../image/444.png';//校区
import whale5 from '../image/555.png';//校区
import whale6 from '../image/666.png';//校区
import "./styleCss.css"
export default class HomPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            MessageVisible: false
        }
    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{}}>
                <div style={{ width: "100%", position: "absolute", top: 0, bottom: 0, left: 0, zIndex: 10, overflow: "hidden", background: "#dee8eb", minWidth: "1200px" }}>

                    <div className='main'>
                        <img style={{ position: "absolute", right: "20px", top: "110px", width: "550px", height: "40px" }} src={yulu}></img>
                        <div onClick={() => this.goPage('教学课程', "/payCourse/courseSystem")}>
                            <img className='whale1' src={whale1}></img>
                        </div>
                        {/* <div onClick={() => this.goPage('教师培训', "/payCourse/teacherTraining")}> */}
                        <div>
                            <img className='whale6' src={whale6}></img>
                        </div>
                        <div onClick={() => this.goPage("校区运营", "/operate/campusOperations")}>
                            <img className='whale5' src={whale5}></img>
                        </div>
                        {/* <div onClick={() => this.goPage("全年活动", "/operate/activity")}>
                            <img className='whale3' src={whale3}></img>
                        </div> */}
                        <div onClick={() => this.goPage("周边订购", "/order")}>
                            <img className='whale4' src={whale4}></img>
                        </div>
                        <div onClick={() => this.goPage("在线直播", "/liveBroadcast")}>
                            <img className='whale2' src={whale2}></img>
                        </div>
                        <div style={{ position: "absolute", bottom: "10px", left: "50px", fontSize: "13px", width: "1600px", color: "#fff" }}><span>河北悦小鲸教育科技有限公司</span><a style={{ color: "#fff", paddingLeft: "20px" }} href="https://beian.miit.gov.cn">冀ICP备2021024602号-1</a></div>
                    </div>
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        visible={this.state.MessageVisible}
                        onCancel={() => { this.setState({ MessageVisible: false }) }}
                        closable={true}
                        width={348}
                    >
                        <div style={{ height: "413px", padding: "43px 34px", background: "#fff", borderRadius: "6px" }}>
                            <Empty description="您没有此模块的权限。" />
                        </div>
                    </Modal>
                </div>
            </div >
        )
    }
    componentDidMount() {
    }
    goPage(Name, Path) {
        if (cookie.load("token") != undefined) {
            if (global.Identity == 1 && ( Name == "校区运营" || Name == "全年活动")) {
                this.setState({ MessageVisible: true })
                return
            }
            if (global.IsLive == 2 && Name == "在线直播" ) {
                this.setState({ MessageVisible: true })
                return
            }
            this.props.history.push(Path,)
        } else {
            this.props.history.push('/login')
        }
    }

}