import styled from "styled-components"


export const Icon = styled.svg`
width:14px;
height:14px;
fill:#fff;
:hover{
    cursor:${props => props.pointer === 1 ? 'pointer' : 'default'};
}
`
export const Search = styled.div`
background:#87CEFA;
height:40px;
margin-top:5px;
border-radius:40px;
:hover{
   width:200px;
//    padding:0px 6px;
transition:2s;
}
`
export const SearchInput = styled.input`
border:none;
background:none;
outline:none;
float:left;
padding:0px;
color:#fff;
font-size:16px;
line-height:40px;
width:0px;
transition:2s;
:hover{
    width:200px;
    transition:2s;
 }
`
export const SearchBtn = styled.a`
border:none;
background:#87CEFA;
float:left;
border-radius:50%;
color:#e84118;;
width:40px;
height:40px;
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;
transition:2s;
:hover{
    background:blue;
 }
`
export const InfoRight = styled.div`
display:flex;
flex-direction:column;
flex:2.5;
box-sizing:border-box;
padding:2rem;
width:100%;
height:27rem;
>div{
    padding:1rem;
    font-size:0.9rem;
    >input{
        width:10rem;
        height:1.25rem;
        border:.1rem solid #999;
        border-radius:.3rem;
        outline:none;
        padding:0 1rem;
    }
    
    >div{
        padding:1rem 0;
        >button{
            display:block;
            height:1.6rem;
            width:3.8rem;
            font-weight:bold;
            color:#fff;
            border:.1rem solid #fff;
            background:#77c4d3;
            border-radius:1rem;
            :hover{
                cursor: pointer;
            }
        }
    }
}
`