import React, { Component } from 'react'
// import * as styled from '../utlis/styled'
import cookie from "react-cookies";
import service from '../../utlis/request';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, message, Select, Input, Space, Table, Tabs, Spin } from 'antd';
import "../styleCss.css"
import '../../index.css'
import weixinIcon from "../../image/wxzf.webp"
import zhifubaoIcon from "../../image/zhifubao.jpg"
const { Option } = Select;
const { TabPane } = Tabs;
export default class MyOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            header: 'https://api2.yuexiaojing.com/v3/',
            page: 1,
            count: 0,
            StateType: 0,
            OrederList: [],
            DetailsVisible: false,
            TableItem: {},
            StateList: [{ Name: "全部", ID: 0, }, { Name: "未支付", ID: 1, }, { Name: "未发货", ID: 2, }, { Name: "已完成", ID: 3, },],
            StateType: 0,
            ID: 0,
            DeleteVisible: false,
            PayVisible: false,
            PayType: 1,
            Loading: false,
            orderState: false,
        }
    }
    render() {
        const columns = [
            { title: '订单名称', dataIndex: 'Name', key: 'Name' },
            {
                title: '订单编号', dataIndex: 'Number', key: 'Number',
            },
            { title: '商品数量', dataIndex: 'Amount', key: 'Amount', },
            {
                title: '订单金额', dataIndex: 'Price', key: 'Price',
                render: (text, record, index) => {
                    return <div>
                        {record.Price / 100}
                    </div>
                }
            },
            {
                title: '状态', dataIndex: 'IsGrounding', key: 'IsGrounding',
                render: (text, record, index) => {
                    return <div>
                        {record.State == 1 ? <div>未支付</div> : ""}
                        {record.State == 2 ? <div>未发货</div> : ""}
                        {record.State == 3 ? <div>已完成</div> : ""}
                    </div>
                }
            },
            {
                title: '操作', dataIndex: '', key: 'operation', render: (text, record, index) => {
                    return <div>
                        {record.State == 1 ? <Button onClick={() => this.setState({ TableItem: record, PayVisible: true }, () => { this.getWeChatPay(); this.getlunxunData() })} style={{ color: "#0081FF", marginLeft: "20px" }} >支付</Button> : ""}
                        <Button onClick={() => this.setState({ TableItem: record }, () => { this.setState({ DetailsVisible: true }) })} style={{ color: "#0081FF", marginLeft: "20px" }} >详情</Button>
                        {record.State == 1 ? <Button onClick={() => this.setState({ ID: record.ID }, () => { this.setState({ DeleteVisible: true }) })} style={{ color: "red", marginLeft: "20px" }} >删除</Button> : ""}

                    </div>
                }
            },
        ];
        const paginationProps = {
            current: this.state.page, //当前页码
            pageSize: 10, // 每页数据条数
            showTotal: (total) => (
                <span>共{total}条</span>
            ),
            total: this.state.count, // 总条数
            onChange: page => handlePageChange(page), //改变页码的函数
            hideOnSinglePage: false,
            showSizeChanger: false,
        }
        const handlePageChange = (page) => {
            this.setState({ page: page })
            this.paging(page)
        }
        return (
            <div style={{}}>
                <div style={{ width: "100%", height: "60px", padding: "10px 20px", borderBottom: "1px solid #EBEBEB", }}>
                    <div style={{ height: "40px", width: "100%", display: "flex", borderLeft: "6px solid #7AC4D9", lineHeight: "40px", fontSize: "18px", color: "#7AC4D9", paddingLeft: "20px" }}>
                        我的订单
                    </div>
                </div>
                <div style={{ display: 'flex', margin: "15px 0px" }}>
                    {
                        this.state.StateList.map((item, index) => {
                            return <div style={{ display: "flex" }}>
                                <Button type="primary" style={{ background: this.state.StateType == item.ID ? "#53bfe5" : "#fff", border: "1px solid #ccc", color: this.state.StateType == item.ID ? "#fff" : "#000", marginLeft: "20px" }} onClick={() => this.setState({ StateType: item.ID }, () => { this.paging(1) })}>{item.Name}</Button>
                            </div>
                        })
                    }
                </div>
                <div>
                    <Tabs type="card" size={"middle"} destroyInactiveTabPane={true} onTabClick={(e) => this.OpenType(e)}>
                        <TabPane tab="周边订购" key={1} forceRender={true}>
                            <div style={{ padding: "0px 10px" }}>
                                <Table columns={columns}
                                    style={{ color: "" }}
                                    dataSource={this.state.OrederList}
                                    className="table"
                                    bordered={true}
                                    pagination={paginationProps}
                                    rowKey={record => record.ID}
                                    size='small'
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="付费课程" key={2} forceRender={true}>
                            <div style={{ padding: "0px 10px" }}>
                                <Table columns={columns}
                                    style={{ color: "" }}
                                    dataSource={this.state.OrederList}
                                    className="table"
                                    bordered={true}
                                    pagination={paginationProps}
                                    rowKey={record => record.ID}
                                    size='small'
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                    {/* 删除 */}
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.DeleteVisible}
                        onCancel={() => this.setState({ DeleteVisible: false })}
                        closable={true}
                        width={400}
                    >
                        <div style={{ heighe: "205px", padding: "10px 20px" }}>
                            <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                            <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => { this.DeleteOrder() }}>确认</Button>
                                <Button style={{ color: "#7AC4D9", }} onClick={() => { this.setState({ DeleteVisible: false }) }}>取消</Button>
                            </div>
                        </div>
                    </Modal>
                    {/* 详情 */}
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.DetailsVisible}
                        onCancel={() => this.setState({ DetailsVisible: false })}
                        closable={true}
                        width={700}
                    >
                        <div style={{ heighe: "100%", }}>
                            <div style={{ width: "100%", height: "40px", lineHeight: "40px", color: "#8291A9", fontSize: "20px", marginBottom: "20px" }}>订单详情</div>
                            <Space
                                direction="vertical"
                                size="large"
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>订单编号：</div>
                                    <div >{this.state.TableItem.Number}</div>
                                </Space>
                                <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>商品名称：</div>
                                    <div >{this.state.TableItem.Name}</div>
                                </Space>
                                <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>商品数量：</div>
                                    <div >{this.state.TableItem.Amount}</div>
                                </Space>
                                <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>订单金额：</div>
                                    <div >{this.state.TableItem.Price / 100}</div>
                                </Space>
                                {this.state.TableItem.PayTime != undefined ? <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>时间：</div>
                                    <div >{this.timeChange(this.state.TableItem.PayTime)}</div>
                                </Space> : ""}
                                <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>订单状态：</div>
                                    {this.state.TableItem.State == 1 ? <div>未支付</div> : ""}
                                    {this.state.TableItem.State == 2 ? <div>未发货</div> : ""}
                                    {this.state.TableItem.State == 3 ? <div>已完成</div> : ""}
                                </Space>
                                {this.state.TableItem.Category == 1 && this.state.TableItem.State == 3 ?
                                    <><Space align="center">
                                        <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>快递编号：</div>
                                        <div>{this.state.TableItem.ExpressNumber}</div>
                                    </Space>
                                        <Space align="center">
                                            <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>快递公司：</div>
                                            <div>{this.state.TableItem.MailingCompany}</div>
                                        </Space></> : ""}
                                {this.state.TableItem.Category == 1 ? <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>收货人：</div>
                                    <div >{this.state.TableItem.People}</div>
                                </Space> : ""}
                                {this.state.TableItem.Category == 1 ? <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>收货人电话：</div>
                                    <div >{this.state.TableItem.Phone}</div>
                                </Space> : ""}
                                {this.state.TableItem.Category == 1 ? <Space align="center">
                                    <div style={{ width: "100px", textAlign: 'right', color: "rgb(146 131 131)" }}>收货人地址：</div>
                                    <div >{this.state.TableItem.Province + this.state.TableItem.City + this.state.TableItem.Area + this.state.TableItem.Detailed}</div>
                                </Space> : ""}
                            </Space>

                        </div>
                    </Modal>
                    {/* 支付 */}
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.PayVisible}
                        onCancel={() => this.setPay()}
                        closable={true}
                        width={700}
                    >
                        <div style={{ heighe: "100%", }}>
                            <div style={{ width: "100%", height: "40px", lineHeight: "40px", color: "#8291A9", fontSize: "20px", }}>支付</div>
                            <Spin spinning={this.state.Loading} style={{ position: "fixed", top: "20%" }} tip="支付成功" size="large">
                                <div style={{ width: "100%", height: "100%", background: "#fff", borderRadius: "10px", padding: "10px", fontSize: "14px" }}>
                                    <p style={{ height: "60px", fontSize: "20px", textAlign: "center", fontWeight: "700", borderBottom: "1px solid #C2C2C2" }}>支付信息</p>
                                    <div>
                                        <div style={{ display: "flex", margin: "20px 0px", fontSize: "16px" }}>
                                            <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>订单号</div>
                                            <div>{this.state.TableItem.Number}</div>
                                        </div>
                                        <div style={{ display: "flex", margin: "20px 0px", fontSize: "16px" }}>
                                            <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>商品名称</div>
                                            <div>{this.state.TableItem.Name}</div>
                                        </div>
                                        <div style={{ display: "flex", margin: "20px 0px", fontSize: "16px" }}>
                                            <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>支付金额</div>
                                            <div style={{ color: "red" }}>{this.state.TableItem.Price / 100}元</div>
                                        </div>
                                    </div>
                                    <Tabs defaultActiveKey="1" type="card" size={"middle"} destroyInactiveTabPane={true} onTabClick={(e) => this.OpenPayType(e)}>
                                        <Tabs.TabPane tab="微信" key="1">
                                            <div style={{ width: "100%", textAlign: "center", height: "350px" }}>
                                                <img style={{ width: "200px", height: "100px" }} src={weixinIcon} />
                                                <p>
                                                    <img style={{ width: "200px", height: "200px" }} src={'data:image/png;base64,' + this.state.WeChatPayCode} />
                                                </p>
                                            </div>
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="支付宝" key="2">
                                            <div style={{ width: "100%", textAlign: "center", height: "350px", padding: "20px 0px 0px 0px" }}>
                                                <img style={{ width: "200px", height: "80px", marginBottom: "10px" }} src={zhifubaoIcon} />
                                                <p >
                                                    <img style={{ width: "200px", height: "200px" }} src={'data:image/png;base64,' + this.state.AliPayCode} />
                                                </p>
                                            </div>
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div >
                            </Spin>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.paging(1)
    }
    timeChange(time) {
        if (time != undefined) {
            console.log(time)
            var date = time.substr(0, 10); //年月日
            var hours = time.substring(11, 13);
            var minutes = time.substring(14, 16);
            var seconds = time.substring(17, 19);
            var timeFlag = date + ' ' + hours + ':' + minutes + ':' + seconds;
            timeFlag = timeFlag.replace(/-/g, "/");
            timeFlag = new Date(timeFlag);
            timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
            timeFlag = timeFlag.getFullYear() + '-' + ((timeFlag.getMonth() + 1) < 10 ? "0" + (timeFlag.getMonth() + 1) : (timeFlag.getMonth() + 1)) + '-' + (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) + ' ' + timeFlag.getHours() + ':' + timeFlag.getMinutes() + ':' + (timeFlag.getSeconds() < 10 ? "0" + timeFlag.getSeconds() : timeFlag.getSeconds());
            return timeFlag;
        }
    };
    paging(page) {
        let limit = 10
        let newoffset = (page - 1) * limit
        service.request({
            method: 'get',
            url: service.api.homeURL + '/order',
            params: {
                limit: 10,
                offset: newoffset,
                state: this.state.StateType,
                category: this.state.Type
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({ OrederList: res.data, count: res.count, page: page, })
            } else {
                message.error("系统繁忙，请重试")
            }
        })
    }

    OpenType(e) {
        this.setState({ Type: e }, () => { this.paging(1) })
    }
    DeleteOrder() {
        service.request({
            url: service.api.homeURL + '/order',
            method: "delete",
            data: {
                ID: this.state.ID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.setState({ DeleteVisible: false })
                this.paging(1)
                message.success('删除成功');
            } else {
                message.error('删除失败，请重试');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
    OpenPayType(e) {
        this.setState({
            PayType: e
        }, () => {
            if (this.state.PayType == 1) {
                this.getWeChatPay()
            } else {
                this.getAliPay()
            }
        })
    }
    getWeChatPay() {
        service.request({
            url: service.api.homeURL + '/order/weChatPay',
            method: "post",
            data: {
                ID: this.state.TableItem.ID
            },
        }).then(res => {
            if (res.code == 200) {
                this.setState({ WeChatPayCode: res.data })
                // this.getlunxunData()
            } else {

            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
    getAliPay() {
        service.request({
            url: service.api.homeURL + '/order/aliPay',
            method: "post",
            data: {
                ID: this.state.TableItem.ID
            },
        }).then(res => {
            if (res.code == 200) {
                this.setState({ AliPayCode: res.data })
                // this.getlunxunData()
            } else {

            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
    getlunxunData() {
        this.timer = window.setInterval(() => {
            setTimeout(() => {
                if (this.state.orderState == false) {
                    this.getOrderData()
                } else {
                    window.clearInterval(this.timer)
                }
            }, 0)
        }, 5000)
    }
    getOrderData() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/order/queryByID',
            params: {
                orderID: this.state.TableItem.ID
            }
        }).then(res => {
            if (res.code === 200) {
                if (res.data.State != 1) {
                    message.success("支付成功")
                    this.setState({
                        orderState: true,
                        Loading: true
                    }, () => {
                        this.paging(1)
                        window.clearInterval(this.timer)
                        setTimeout(() => {
                            this.setState({
                                Loading: false,
                                PayVisible: false
                            })
                        }, 3000)
                    })
                }
            } else {
                message.error("请求有误，请重试")
            }
        })
    }
    setPay() {
        this.setState({ PayVisible: false }, () => {
            window.clearInterval(this.timer)
        })
    }
    componentWillUnmount() {
        window.clearInterval(this.timer)
    }
}