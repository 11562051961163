import React, { Component } from 'react'
import service from '../../../utlis/request'
export default class Activity_phase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            PhaseList: [],
            ParentID: 0,
            nav_arr: [],
            TableItem: {},
            header: 'https://api2.yuexiaojing.com/v3/'
        }

    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "40px" , width: "1200px"}}>
                {this.state.nav_arr.length > 0 ? <div style={{ display: "flex", marginBottom: "15px", }}>
                    {
                        this.state.nav_arr.map((item, index) => {
                            if (index < 1) {
                                return <div key={index} style={{ marginLeft: "5px", color: "#0684b0", cursor: "pointer", fontSize: "25px", }}>
                                    {item.Name}
                                </div>
                            } else {
                                return <div key={index} style={{ marginLeft: "5px", color: "#0684b0", cursor: "pointer", fontSize: "25px", }}>
                                    <span>{">"}</span>
                                    {item.Name} </div>
                            }

                        })
                    }
                </div> : ''}

                <div style={{ width: "100%", marginTop: "15px", display: "flex", flexWrap: "wrap", }}>
                    {
                        this.state.PhaseList.map((item, index) => {
                            return <div key={index} onClick={() => this.switchActivity(item)} index={index} style={{ width: "260px", height: "180px", cursor: "pointer", fontSize: "25px", color: "#239bc6", position: "relative", margin: "20px", border: "2px solid #239bc6", borderRadius: "6px", objectFit: "contain", }}>
                                <img src={this.state.header + item.Cover} style={{ width: "256px", height: "176px", borderRadius: "6px", }}></img>
                                {/* <p style={{ width: "260px", height: "180px", lineHeight: "180px", textAlign: "center", fontSize: "20px", position: "absolute", top: "0px", left: "0px" }}>{item.Name ? (item.Name.length > 10 ? item.Name.substring(0, 10) + "..." : item.Name) : ""}</p> */}
                            </div>
                        })
                    }

                </div>
            </div>
        )
    }
    componentDidMount() {
        this.getActivity()
    }
    getActivity() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/activity',
            params: {
                offset: 0,
                limit: 0,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    PhaseList: res.data
                })
            } else {
            }
        })
    }
    switchActivity(item) {
        this.props.setCourse(item)
        this.state.nav_arr.push({ Name: "全年活动" })
        this.state.nav_arr.push(item)
        this.setState({}, () => {
            this.props.setPage(2, this.state.nav_arr)
        })

    }
    // 鼠标事件
    enter(ID, index) {
        if ((index + 1) % 4 == 1) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #492bce"
            // e.target.style.background = "#bfecd7"
        }
        if ((index + 1) % 4 == 2) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #22d9e6"
            // e.target.style.background = "#bfecd7"
        }
        if ((index + 1) % 4 == 3) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #73f909"
            // e.target.style.background = "#bfecd7"
        }
        if ((index + 1) % 4 == 4) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #976c05"
            // e.target.style.background = "#bfecd7"
        }
    }
    remove(ID, index) {
        var doc = document.getElementById(ID)
        doc.style.border = "2px solid #fff"
        // e.target.style.background = 'none'
    }
}