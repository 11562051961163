import React from 'react';
import { Watermark } from 'antd'
//
const rootRef = React.createRef();

export default class PPT extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            header: 'https://api2.yuexiaojing.com/v3/'
        }
    }
    componentDidMount() {
        // 监听页面全屏事件
        window.onresize = () => {
            // 全屏
            if (document.fullscreenElement) {
                this.setState({ isFullScreen: true });
            }
            // 不是全屏
            else {
                this.setState({ isFullScreen: false });
            }
        }
    }

    componentWillUnmount() {

    }
    componentDidUpdate() {

    }
    render() {
        return (
            <div ref={rootRef} style={{ width: "100%", height: "100%", position: "relative", }}>
                <Watermark style={{ width: "100%", height: "100%" }} content={global.Watermark} gap={[500,500]}>
                    {this.state.isFullScreen ? <div onClick={() => this.exitFullScreen()} style={{ width: "36px", height: "26px", color: "#ffffff", background: "#d24726", position: "absolute", bottom: "0px", right: "0px", zIndex: 111, cursor: "pointer", textAlign: "center", fontSize: "12px", lineHeight: "23px" }}>退出</div> :
                        <div onClick={() => this.fullScreen()} style={{ width: "42px", height: "26px", color: "#ffffff", background: "#d24726", position: "absolute", bottom: "0px", right: "0px", zIndex: 111, cursor: "pointer", textAlign: "center", fontSize: "12px", lineHeight: "23px" }}>全屏</div>}
                    <iframe title="ppt" width="100%" height="100%" frameBorder="0" src={"https://vip.ow365.cn?i=30293&ssl=1&furl=" + this.state.header + this.props.path}></iframe>
                </Watermark>
            </div>
        );
    }
    // 全屏
    fullScreen() {
        if (!this.state.isFullScreen) {
            rootRef.current.requestFullscreen();
        }
    }
    // 退出全屏
    exitFullScreen() {
        document.exitFullscreen();
    }
}