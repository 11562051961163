import React, { Component } from 'react'
import c2 from '../../../image/22.png';
import xiala from '../../../image/xiala.png';
import { Button, message, Empty, Modal, Input, Spin, } from 'antd';
import service from '../../../utlis/request'
export default class CourseSystem_phase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bgc: "",
            onPhaseName: '',
            PhaseList: [],
            chapterType: 1,
            chapterItem: { Name: "常规课程", ID: 1, Type: 1, },
            chapterName: "常规课程",
            OutlineState: true,

            chapterList: [{ Name: "常规课程", ID: 1, Type: 1, }, { Name: "假期课程", ID: 2, Type: 2, }, { Name: "工具课程", ID: 3, Type: 3, }, { Name: "特色课程", ID: 4, Type: 4, },],
            MessageVisible: false,
            switchID: '',
            header: 'https://api2.yuexiaojing.com/v3/',
            spinning: true,
        }

    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{ position: "relative", marginTop: "20px", paddingTop: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ paddingLeft: "5px", height: "33px", display: this.state.OutlineState ? "flex" : "none", justifyContent: "flex-start", }}>
                        {
                            this.state.chapterList.map((item, index) => {
                                return <div key={index} onClick={() => this.switchOutline(item)} index={index} style={{ width: "98px", height: "33px", marginRight: "30px", fontSize: "16px", cursor: "pointer", textAlign: "center", border: this.state.chapterName == item.Name ? "2px solid #e08400" : "2px solid #3c9cbc", lineHeight: "30px", borderRadius: "6px", color: this.state.chapterName == item.Name ? "#fff" : "#000", background: this.state.chapterName == item.Name ? "#ffa61a" : "#73c4df" }}>{item.Name}</div>
                            })
                        }
                    </div>
                    <div style={{ display: "flex", fontSize: "25px", color: "#239bc6", paddingRight: "5px" }} >
                        <Input placeholder="输入名称进行搜索" style={{ width: "250px", height: "33px", borderRadius: "8px 0px 0px 8px", border: "none" }} value={this.state.onPhaseName} onChange={(e) => { this.setState({ onPhaseName: e.target.value },) }} />
                        <Button style={{ borderRadius: "0px 8px 8px 0px", height: "33px", background: "#47b3d8", color: "#fff" }} onClick={() => this.setState({ OutlineState: false, }, () => { this.getPhase() })}>搜索</Button>
                        <Button style={{ borderRadius: "8px", height: "33px", background: "#47b3d8", color: "#fff", marginLeft: "20px" }} onClick={() => this.setState({ OutlineState: true, onPhaseName: "", }, () => { this.getPhase() })}>重置</Button>
                    </div>
                </div>

                {/* /////// */}
                <div style={{ width: "100%", marginTop: "35px" }}>
                    {
                        this.state.PhaseList.map((item, index) => {
                            return <div style={{ marginBottom: "25px" }}>
                                <div id={item.ID} key={index} onMouseEnter={() => this.enter(item.ID, index)} onMouseLeave={() => this.remove(item.ID, index)} onClick={() => this.switchPhase(item, index)} index={index} style={{ width: "100%", height: "150px", fontSize: "25px", cursor: "pointer", borderRadius: "12px", padding: "15px", display: "flex", position: "relative" }}>
                                    <div id={"n" + item.ID} style={{ borderRadius: "12px", padding: "15px", marginBottom: "25px", width: "100%", height: "100%", background: "#fff", position: "absolute", zIndex: "-1", top: "0", left: "0" }}></div>
                                    <div id={"y" + item.ID} className='courseSwitch' style={{ display: this.state.switchID == item.ID ? "" : "none", borderRadius: "12px", padding: "15px", marginBottom: "25px", width: "100%", height: "100%", position: "absolute", zIndex: "-1", top: "0", left: "0" }}></div>
                                    <img id={"i" + item.ID} style={{ width: "217px", height: "120px", objectFit: "contain", borderRadius: "6px", background: this.state.switchID == item.ID ? '#fff' : '#eefaff' }} src={c2}></img>
                                    <div style={{ height: "100px", lineHeight: "100px", color: "#000", marginLeft: "40px", fontSize: "18px" }}>{item.Name}</div>
                                    <img id={"x" + item.ID} style={{ position: "absolute", right: "-50px", top: "59px", animation: this.state.switchID == item.ID ? '0.5s ease 0s 1 normal forwards running rotate' : '' }} src={xiala}></img>
                                </div>
                                <div style={{ display: this.state.switchID == item.ID ? "" : "none", width: "calc(100% - 30px)", minHeight: "125px", background: "#fff", margin: 'auto', borderRadius: "0 0 12px 12px", textAlign: "center" }}>
                                    {item.children ? item.children.map((item, index) => {
                                        return <div id={"z" + item.ID} key={index} onMouseEnter={() => this.enterCourse(item.ID)} onMouseLeave={() => this.removeCourse(item.ID)} onClick={() => this.switchCourse(item, index)} index={index} style={{ width: "calc(100% - 40px)", height: "125px", fontSize: "25px", cursor: "pointer", background: "#fff", borderRadius: "12px", padding: "8px", marginLeft: "20px", marginRight: "20px", display: "flex", position: "relative" }}>
                                            <img id={"zi" + item.ID} style={{ width: "200px", height: "105px", objectFit: "contain", borderRadius: "6px", background: this.state.switchID == item.ID ? '#fff' : '#eefaff' }} src={this.state.header + item.Cover}></img>
                                            <div style={{ height: "100px", lineHeight: "100px", color: "#000", marginLeft: "40px", fontSize: "18px" }}>{item.Name}</div>
                                        </div>
                                    }) : ''
                                    }
                                    <Spin spinning={this.state.spinning} tip="加载中" />
                                </div>
                            </div>
                        })
                    }
                </div>
                <Modal
                    destroyOnClose={true}
                    centered={true}
                    title={null}
                    footer={null}
                    modalRender={(modal) => (
                        modal
                    )}
                    // bodyStyle={{ padding: '0px', }}
                    visible={this.state.MessageVisible}
                    onCancel={() => { this.setState({ MessageVisible: false }) }}
                    closable={true}
                    width={348}
                >
                    <div style={{ height: "413px", padding: "43px 34px", background: "#fff", borderRadius: "6px" }}>
                        <Empty description="没权限,请联系管理员" />
                    </div>
                </Modal>
            </div>
        )
    }
    componentDidMount() {
        this.queryPlate()
    }
    //
    queryPlate() {
        service.request({
            url: service.api.homeURL + "/plate",
            method: "get",
            params: {
                limit: 0,
                offset: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ chapterList: res.data, chapterType: res.data[0].ID, chapterItem: res.data[0], chapterName: res.data[0].Name }, () => {
                    if (this.props.nav_arr && this.props.nav_arr.length > 0) {
                        var b = false
                        if (this.props.PhaseItem != {}) {
                            b = true
                        }
                        this.switchOutline(this.props.nav_arr[0], b, this.props.PhaseItem)
                    } else {
                        this.getPhase()
                    }
                })
            }
        }).catch(function (error) {
            message.error('系统繁忙');
        })
    }
    //
    switchPhase(item, index) {
        if (item.ID == this.state.switchID) {
            this.setState({ switchID: '' })
            //
            this.props.setPhase({})
            return
        }
        if (global.ViewPhase.length > 0) {
            let b = false
            global.ViewPhase.map((i, j) => {
                if (!b) {
                    if (i.Name == item.Name) {
                        b = true
                    }
                }
            })
            if (b) {
                var nav_arr = []
                nav_arr.push(this.state.chapterItem)
                nav_arr.push(item)
                this.setState({
                    PhaseItem: item,
                    switchID: item.ID,
                    nav_arr: nav_arr,
                })
                //
                this.getCourse(item)
                //
                this.props.setPhase(item)
            } else {
                this.setState({ MessageVisible: true })
            }

        } else {
            this.setState({ MessageVisible: true })
        }

        if (global.DownloadPhase.length > 0) {
            var b = false
            global.DownloadPhase.map((i, j) => {
                if (i.Name == item.Name) {
                    b = true
                }
            })
            this.props.setDownloadPhase(b)
        } else {
            this.props.setDownloadPhase(false)
        }
    }
    enter(ID) {
        var doc = document.getElementById("y" + ID)
        doc.style.display = ''

        var img = document.getElementById("i" + ID)
        img.style.background = '#fff'
    }
    remove(ID) {
        if (this.state.switchID != ID) {
            var doc = document.getElementById("y" + ID)
            doc.style.display = 'none'

            var img = document.getElementById("i" + ID)
            img.style.background = '#eefaff'
        }
    }
    switchOutline(item, b, PhaseItem) {
        this.setState({
            chapterItem: item,
            chapterType: item.ID,
            chapterName: item.Name,
            PhaseList: [],
            switchID: ''
        }, () => {
            this.getPhase(b, PhaseItem)
        })

    }
    getPhase(b, PhaseItem) {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/phase',
            params: {
                offset: 0,
                limit: 0,
                plateID: this.state.chapterType,
                name: this.state.onPhaseName
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    PhaseList: res.data,
                }, () => {
                    if (b) {
                        var doc = document.getElementById("y" + PhaseItem.ID)
                        doc.click()
                        this.props.setScrollTop()
                    }
                })
                //
            } else {
                message.error("系统繁忙")
            }
        })
    }
    //课程
    getCourse(item) {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/course',
            params: {
                offset: 0,
                limit: 0,
                phaseID: item.ID
            }
        }).then(res => {
            if (res.code === 200) {
                item.children = res.data
                this.setState({ spinning: false })
            }
        })
    }
    enterCourse(ID) {
        var doc = document.getElementById("z" + ID)
        doc.style.background = '#f3f9fb'

        var img = document.getElementById("zi" + ID)
        img.style.background = '#fff'
    }
    removeCourse(ID) {
        if (this.state.switchID != ID) {
            var doc = document.getElementById("z" + ID)
            doc.style.background = '#fff'

            var img = document.getElementById("zi" + ID)
            img.style.background = '#eefaff'
        }
    }
    switchCourse(item) {
        this.props.getScrollTop()
        this.state.nav_arr.push(item)
        this.setState({})
        if (item.Type == 1) {
            this.props.setPage(3, this.state.nav_arr)
            this.props.setCourse(item)
        } else {
            this.props.setPage(4, this.state.nav_arr)
            this.props.setCourse(item)
        }
    }
}