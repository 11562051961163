import React, { Component } from 'react'
import { Button, message, Space, Spin, Tabs, } from 'antd';
import service from '../../utlis/request'
import "../../index.css"
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import weixinIcon from "../../image/wxzf.webp"
import zhifubaoIcon from "../../image/zhifubao.jpg"
export default class OrderPay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            TableItem: {},
            header: 'https://api2.yuexiaojing.com/v3/',
            Type: 1,
            WeChatPayCode: "",
            AliPayCode: "",
            orderState: false,
            Loading: false,
        }

    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "20px" }}>
                <Spin spinning={this.state.Loading} style={{ position: "fixed", top: "20%" }} tip="支付成功跳转至订单页......" size="large">
                    <div style={{ width: "100%", height: "100%", marginTop: "15px", background: "#fff", borderRadius: "10px", padding: "51px 30px 51px 30px", fontSize: "14px" }}>
                        <p style={{ height: "60px", fontSize: "20px", textAlign: "center", fontWeight: "700", borderBottom: "1px solid #C2C2C2" }}>支付信息</p>
                        <div>
                            <div style={{ display: "flex", margin: "20px 0px", fontSize: "16px" }}>
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>订单号</div>
                                <div>{this.state.TableItem.Number}</div>
                            </div>
                            <div style={{ display: "flex", margin: "20px 0px", fontSize: "16px" }}>
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>商品名称</div>
                                <div>{this.state.TableItem.Name}</div>
                            </div>
                            <div style={{ display: "flex", margin: "20px 0px", fontSize: "16px" }}>
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", marginRight: "15px" }}>支付金额</div>
                                <div style={{ color: "red" }}>{this.state.TableItem.Price / 100}元</div>
                            </div>
                        </div>
                        <Tabs defaultActiveKey="1" type="card" size={"middle"} destroyInactiveTabPane={true} onTabClick={(e) => this.OpenType(e)}>
                            <Tabs.TabPane tab="微信" key="1">
                                <div style={{ width: "100%", textAlign: "center", height: "350px" }}>
                                    <img style={{ width: "200px", height: "100px" }} src={weixinIcon} />
                                    <p>
                                        <img style={{ width: "200px", height: "200px" }} src={'data:image/png;base64,' + this.state.WeChatPayCode} />
                                    </p>
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="支付宝" key="2">
                                <div style={{ width: "100%", textAlign: "center", height: "350px", padding: "20px 0px 0px 0px" }}>
                                    <img style={{ width: "200px", height: "80px", marginBottom: "10px" }} src={zhifubaoIcon} />
                                    <p >
                                        <img style={{ width: "200px", height: "200px" }} src={'data:image/png;base64,' + this.state.AliPayCode} />
                                    </p>
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                        <div style={{ fontSize: "18px", color: "#8C8C8C", fontSize: "18px", display: "flex", marginBottom: "15px", width: "100%", justifyContent: "flex-end" }}>
                            <Button onClick={() =>
                                 this.props.setPage(1)
                                // this.props.history.push('/order')
                            } style={{ background: "#fff", color: "#000", marginLeft: "40px", }}>返回</Button>
                        </div>
                    </div >
                </Spin>
            </div >
        )
    }
    componentDidMount() {
        this.setState({
            TableItem: this.props.OrderInfoItem
        },()=>{
            if (this.state.Type == 1) {
                this.getWeChatPay()
            } else {
                this.getAliPay()
            }
        })
        this.getlunxunData()
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }
    OpenType(e) {
        this.setState({
            Type: e
        }, () => {
            if (this.state.Type == 1) {
                this.getWeChatPay()
            } else {
                this.getAliPay()
            }
        })
    }
    getWeChatPay() {
        service.request({
            url: service.api.homeURL + '/order/weChatPay',
            method: "post",
            data: {
                ID: this.state.TableItem.ID
            },
        }).then(res => {
            if (res.code == 200) {
                this.setState({ WeChatPayCode: res.data })
            } else {

            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
    getAliPay() {
        service.request({
            url: service.api.homeURL + '/order/aliPay',
            method: "post",
            data: {
                ID: this.state.TableItem.ID
            },
        }).then(res => {
            if (res.code == 200) {
                this.setState({ AliPayCode: res.data })
            } else {

            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
    getlunxunData() {
        this.timer = window.setInterval(() => {
            setTimeout(() => {
                if (this.state.orderState == false) {
                    this.getOrderData()
                } else {
                    window.clearInterval(this.timer)
                }
            }, 0)
        }, 5000)
    }
    getOrderData() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/order/queryByID',
            params: {
                orderID: this.state.TableItem.ID
            }
        }).then(res => {
            if (res.code === 200) {
                if (res.data.State != 1) {
                    message.success("支付成功")
                    this.setState({
                        orderState: true,
                        Loading: true
                    }, () => {
                        window.clearInterval(this.timer)
                        setTimeout(() => {
                            this.setState({
                                Loading: false
                            })
                            this.props.history.push({ pathname: "/UserInfo", state: 5 })
                        }, 3000)

                    })
                }
            } else {
                message.error("请求有误，请重试")
            }
        })
    }
}