import React, { Component } from 'react'
// import * as styled from '../utlis/styled'
import cookie from "react-cookies";
import service from '../../utlis/request';
import { Button, Modal, message, Select, Input, Space, Table, Switch, Spin } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
export default class Address extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ProvinceList: [],
            CityList: [],
            AreaList: [],

            ParentCode: 0,
            ProvinceCode: 0,
            Province: "",
            CityCode: 0,
            City: "",
            DistrictCode: 0,
            District: "",
            List: [],
            Address: "",
            Name: "",
            Phone: "",
            IsDefault: 2,

            addressItem: {},
            editorAddressVisible: false,
            editorAddressLoading: false,

            editorCityList: [],
            editorAreaList: [],
            editorParentCode: 0,
            editorProvinceCode: 0,
            editorProvince: "",
            editorCityCode: 0,
            editorCity: "",
            editorDistrictCode: 0,
            editorDistrict: "",
            editorAddress: "",
            editorName: "",
            editorPhone: "",
            editorIsDefault: 1,
            ID: 0,
        }
    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        const columns = [
            { title: '收货人', dataIndex: 'People', key: 'People', width: 100 },
            { title: '联系电话', dataIndex: 'Phone', key: 'Phone', width: 100 },
            {
                title: '地址', dataIndex: 'Address', key: 'Address', width: 120,
                render: (text, record, index) => {
                    return <div>
                        {record.Province + record.City + record.Area}
                    </div>
                }
            },
            {
                title: '详细地址', dataIndex: 'Detailed', key: 'Detailed', width: 120,
            },
            {
                title: '操作', dataIndex: '', key: 'operation', width: 240, render: (text, record, index) => {
                    return <div>
                        <Button onClick={() => this.editorAddress(record)} style={{ color: "#7AC4D9", }} >编辑</Button>
                        <Button onClick={() => this.setState({ ID: record.ID }, () => { this.setState({ deleteAddressVisible: true }) })} style={{ marginLeft: "10px", color: "#7AC4D9", }}>删除</Button>
                        {record.IsDefault != 1 ? <Button onClick={() => this.setIsDefault(record)} style={{ marginLeft: "10px", color: "#7AC4D9", }} >设为默认</Button> : <Button style={{ marginLeft: "20px", color: "#7AC4D9", border: "none" }} >默认</Button>}
                    </div>
                }
            },
        ];
        return (
            <div style={{}}>
                <div style={{ width: "100%", height: "60px", padding: "10px 20px", borderBottom: "1px solid #EBEBEB", }}>
                    <div style={{ height: "40px", width: "100%", display: "flex", borderLeft: "6px solid #7AC4D9", lineHeight: "40px", fontSize: "18px", color: "#7AC4D9", paddingLeft: "20px" }}>
                        收货地址
                    </div>
                </div>
                <div>
                    <div style={{ width: "100%", height: "60px", padding: "0px 20px", fontSize: "16px", lineHeight: "60px", color: "#8291A9" }}>
                        收货地址
                    </div>
                    <div style={{ padding: "0px 20px", }}>
                        <Space
                            direction="vertical"
                            size="large"
                            style={{
                                display: 'flex',
                            }}
                        >
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", fontSize: "14px" }}>地址信息：</div>
                                <Select
                                    showSearch
                                    style={{ width: "160px", }}
                                    placeholder="省"
                                    optionFilterProp="children"
                                    defaultActiveFirstOption={true}
                                    dropdownMatchSelectWidth={true}
                                    value={this.state.Province}
                                    onChange={(text, record,) => {
                                        this.setState({ ParentCode: record.value, ProvinceCode: record.value, Province: record.children, CityCode: 0, City: "", DistrictCode: 0, District: "" }, () => { this.getCity() })
                                    }}
                                >
                                    {/* <Option key={0} value={0} >全部</Option> */}
                                    {this.state.ProvinceList.map((item, index) => (
                                        <Option key={item.ID} value={item.Code} >
                                            {item.Name}
                                        </Option>
                                    ))}
                                </Select>
                                <Select
                                    showSearch
                                    style={{ width: "160px", }}
                                    placeholder="市"
                                    optionFilterProp="children"
                                    defaultActiveFirstOption={true}
                                    dropdownMatchSelectWidth={true}
                                    value={this.state.City}
                                    onChange={(text, record,) => {
                                        this.setState({ CityCode: record.value, ParentCode: record.value, City: record.children, }, () => { this.getDistrict() })
                                    }}
                                >
                                    {/* <Option key={0} value={0} >全部</Option> */}
                                    {this.state.CityList.map((item, index) => (
                                        <Option key={item.ID} value={item.Code} >
                                            {item.Name}
                                        </Option>
                                    ))}
                                </Select>
                                <Select
                                    showSearch
                                    style={{ width: "160px", }}
                                    placeholder="区"
                                    optionFilterProp="children"
                                    defaultActiveFirstOption={true}
                                    dropdownMatchSelectWidth={true}
                                    value={this.state.District}
                                    onChange={(text, record,) => {
                                        this.setState({ DistrictCode: record.value, District: record.children, }, () => { })
                                    }}
                                >
                                    {/* <Option key={0} value={0} >全部</Option> */}
                                    {this.state.AreaList.map((item, index) => (
                                        <Option key={item.ID} value={item.Code} >
                                            {item.Name}
                                        </Option>
                                    ))}
                                </Select>
                            </Space>
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", fontSize: "14px" }}>详细地址：</div>
                                <Input style={{ width: "300px", }} value={this.state.Address} onChange={(e) => { this.setState({ Address: e.target.value }) }} />
                            </Space>
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", fontSize: "14px" }}>收货人姓名：</div>
                                <Input style={{ width: "300px", }} value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value }) }} />
                            </Space>
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", fontSize: "14px" }}>联系电话：</div>
                                <Input style={{ width: "300px", }} value={this.state.Phone} onChange={(e) => { this.setState({ Phone: e.target.value }) }} />
                            </Space>
                            <Space align="center">
                                <div style={{ width: "100px", textAlign: 'right', color: "#8291A9", fontSize: "14px" }}>设为默认：</div>
                                <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.IsDefault == 1 ? true : false} onChange={() => { this.setState({ IsDefault: this.state.IsDefault == 1 ? 2 : 1 }) }} />
                            </Space>
                            <Space align="center" style={{ display: "flex", justifyContent: "space-around", marginBottom: "14px" }}>
                                <Button onClick={() => this.AddAddressSave()} type="primary" style={{ width: "100px", background: "#68c7e4", border: "none" }}>保存</Button>
                            </Space>
                        </Space>
                    </div>
                </div>
                {/* 修改地址 */}
                <Spin spinning={this.state.editorAddressLoading} tip="加载中......" size="large">
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.editorAddressVisible}
                        onCancel={() => this.setState({ editorAddressVisible: false })}
                        closable={true}
                        width={700}
                    >
                        <div style={{ heighe: "100%", }}>
                            <div style={{ width: "100%", height: "40px", lineHeight: "40px", color: "#8291A9", fontSize: "20px", marginBottom: "20px" }}>编辑</div>
                            <div >
                                <Space
                                    direction="vertical"
                                    size="large"
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    <Space align="center">
                                        <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>地址信息：</div>
                                        <Select
                                            showSearch
                                            style={{ width: "160px", }}
                                            placeholder="省"
                                            optionFilterProp="children"
                                            defaultActiveFirstOption={true}
                                            dropdownMatchSelectWidth={true}
                                            value={this.state.editorProvince}
                                            onChange={(text, record,) => {
                                                this.setState({ editorParentCode: record.value, editorProvinceCode: record.value, editorProvince: record.children, }, () => { this.selectCity() })
                                            }}
                                        >
                                            {/* <Option key={0} value={0} >全部</Option> */}
                                            {this.state.ProvinceList.map((item, index) => (
                                                <Option key={item.ID} value={item.Code} >
                                                    {item.Name}
                                                </Option>
                                            ))}
                                        </Select>
                                        <Select
                                            showSearch
                                            style={{ width: "160px", }}
                                            placeholder="市"
                                            optionFilterProp="children"
                                            defaultActiveFirstOption={true}
                                            dropdownMatchSelectWidth={true}
                                            value={this.state.editorCity}
                                            onChange={(text, record,) => {
                                                this.setState({ editorCityCode: record.value, editorParentCode: record.value, editorCity: record.children, }, () => { this.selectDistrict() })
                                            }}
                                        >
                                            {/* <Option key={0} value={0} >全部</Option> */}
                                            {this.state.editorCityList.map((item, index) => (
                                                <Option key={item.ID} value={item.Code} >
                                                    {item.Name}
                                                </Option>
                                            ))}
                                        </Select>
                                        <Select
                                            showSearch
                                            style={{ width: "160px", }}
                                            placeholder="区"
                                            optionFilterProp="children"
                                            defaultActiveFirstOption={true}
                                            dropdownMatchSelectWidth={true}
                                            value={this.state.editorDistrict}
                                            onChange={(text, record,) => {
                                                this.setState({ editorDistrictCode: record.value, editorDistrict: record.children, }, () => { })
                                            }}
                                        >
                                            {/* <Option key={0} value={0} >全部</Option> */}
                                            {this.state.editorAreaList.map((item, index) => (
                                                <Option key={item.ID} value={item.Code} >
                                                    {item.Name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Space>
                                    <Space align="center">
                                        <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>详细地址：</div>
                                        <Input style={{ width: "300px", }} value={this.state.editorAddress} onChange={(e) => { this.setState({ editorAddress: e.target.value }) }} />
                                    </Space>
                                    <Space align="center">
                                        <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>收货人姓名：</div>
                                        <Input style={{ width: "300px", }} value={this.state.editorName} onChange={(e) => { this.setState({ editorName: e.target.value }) }} />
                                    </Space>
                                    <Space align="center">
                                        <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>联系电话：</div>
                                        <Input style={{ width: "300px", }} value={this.state.editorPhone} onChange={(e) => { this.setState({ editorPhone: e.target.value }) }} />
                                    </Space>
                                    <Space align="center">
                                        <div style={{ width: "100px", textAlign: 'right', color: "#8291A9" }}>设为默认：</div>
                                        <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={this.state.editorIsDefault == 1 ? true : false} onChange={() => { this.setState({ editorIsDefault: this.state.editorIsDefault == 1 ? 2 : 1 }) }} />
                                    </Space>
                                </Space>
                            </div>
                            <div style={{ marginTop: "20px", textAlign: "center" }}>
                                <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => this.saveEditorAddress()}>保存</Button>
                                <Button style={{ color: "#7AC4D9", }} onClick={() => this.setState({ editorAddressVisible: false })}>取消</Button>
                            </div>
                        </div>
                    </Modal>
                </Spin>
                {/* 删除课件 */}
                <Modal
                    destroyOnClose={true}
                    centered={true}
                    title={null}
                    footer={null}
                    modalRender={(modal) => (
                        modal
                    )}
                    // bodyStyle={{ padding: '0px' }}
                    open={this.state.deleteAddressVisible}
                    onCancel={() => this.setState({ deleteAddressVisible: false })}
                    closable={true}
                    width={400}
                >
                    <div style={{ heighe: "205px", padding: "10px 20px" }}>
                        <div style={{ fontSize: "20px", }}><ExclamationCircleOutlined style={{ color: "#FDAF4E", fontSize: "20px", marginRight: "10px" }}></ExclamationCircleOutlined>提示</div>
                        <div style={{ marginTop: "20px", color: "#666666" }}>确定删除此条数据？</div>
                        <div style={{ marginTop: "20px", textAlign: "center" }}>
                            <Button type="primary" style={{ background: "#7AC4D9", border: "none", marginRight: "40px" }} onClick={() => { this.DeleteAddress() }}>确认</Button>
                            <Button style={{ color: "#7AC4D9", }} onClick={() => { this.setState({ deleteAddressVisible: false }) }}>取消</Button>
                        </div>
                    </div>
                </Modal>
                <div style={{ padding: "0px 20px", }}>
                    <Table columns={columns}
                        // rowSelection={rowSelection}
                        dataSource={this.state.List}
                        className="table"
                        bordered={true}
                        pagination={false}
                        rowKey={record => record.ID}
                        size='small'
                        scroll={{
                            y: 230,
                        }}
                    />
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.getProvince()
        this.getAddress()
    }
    getProvince() {
        service.request({
            url: service.api.homeURL + '/regional',
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ ProvinceList: res.data, ProvinceCode: res.data[0].Code, ParentCode: res.data[0].Code, }, () => { this.getCity() })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getCity() {
        service.request({
            url: service.api.homeURL + '/regional',
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ CityList: res.data, ParentCode: res.data[0].Code, CityCode: res.data[0].Code, }, () => {
                    this.getDistrict()
                })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getDistrict() {
        service.request({
            url: service.api.homeURL + '/regional',
            method: "get",
            params: {
                parentCode: this.state.ParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ AreaList: res.data, DistrictCode: res.data[0].Code, })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    getAddress() {
        service.request({
            url: service.api.homeURL + '/address',
            method: "get",
            params: {
                offset: 0,
                limit: 0,
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ List: res.data, })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    AddAddressSave() {
        if (this.state.Name != "" && this.state.Phone != "" && this.state.Address != "" && this.state.ProvinceCode != 0 && this.state.CityCode != 0 && this.state.DistrictCode != 0) {
            this.setState({
                AddLoading: true,
            })
            service.request({
                url: service.api.homeURL + "/address",
                method: "post",
                data: {
                    People: this.state.Name,
                    Phone: this.state.Phone,
                    Detailed: this.state.Address,
                    ProvinceCode: this.state.ProvinceCode,
                    CityCode: this.state.CityCode,
                    City: this.state.City,
                    Province: this.state.Province,
                    Area: this.state.District,
                    AreaCode: this.state.DistrictCode,
                    IsDefault: this.state.IsDefault,
                },
            }).then(res => {
                if (res.code == 200) {
                    message.success('添加成功');
                    this.getAddress()
                    this.setState({
                        AddLoading: false,
                        AddVisible: false,
                        ParentCode: 0,
                        ProvinceCode: 0,
                        Province: "",
                        CityCode: 0,
                        City: "",
                        DistrictCode: 0,
                        District: "",
                        Address: "",
                        Name: "",
                        Phone: "",
                        IsDefault: 2,
                    })
                } else {
                    this.setState({
                        AddLoading: false,
                    })
                    if (res.code == 452) {
                        message.error('添加失败,名字重复');
                    } else {
                        message.error('添加失败,请重试');
                    }
                }
            }).catch(function (error) {
                message.error('系统繁忙');
                console.log(error)
            })
        } else {
            message.error('输入不能为空');
        }
    }
    editorAddress(item) {
        this.setState({
            editorParentCode: item.ParentCode,
            editorProvinceCode: item.ProvinceCode,
            editorProvince: item.Province,
            editorCityCode: item.CityCode,
            editorCity: item.City,
            editorDistrictCode: item.AreaCode,
            editorDistrict: item.Area,
            editorAddress: item.Detailed,
            editorName: item.People,
            editorPhone: item.Phone,
            editorIsDefault: item.IsDefault,
            ID: item.ID
        }, () => {
            this.setState({
                editorAddressVisible: true,
            })
        })
    }
    saveEditorAddress() {
        if (this.state.editorName != "" && this.state.editorPhone != "" && this.state.editorAddress != "" && this.state.editorProvinceCode != 0 && this.state.editorCityCode != 0 && this.state.editorDistrictCode != 0) {
            this.setState({
                editorAddressLoading: true,
            })
            service.request({
                url: service.api.homeURL + "/address",
                method: "put",
                data: {
                    People: this.state.editorName,
                    Phone: this.state.editorPhone,
                    Detailed: this.state.editorAddress,
                    ProvinceCode: this.state.editorProvinceCode,
                    CityCode: this.state.editorCityCode,
                    City: this.state.editorCity,
                    Province: this.state.editorProvince,
                    Area: this.state.editorDistrict,
                    AreaCode: this.state.editorDistrictCode,
                    IsDefault: this.state.editorIsDefault,
                    ID: this.state.ID,
                },
            }).then(res => {
                if (res.code == 200) {
                    message.success('添加成功');
                    this.getAddress()
                    this.setState({
                        editorAddressLoading: false,
                        editorAddressVisible: false,
                    })
                } else {
                    this.setState({
                        editorAddressLoading: false,
                    })
                    if (res.code == 452) {
                        message.error('添加失败,名字重复');
                    } else {
                        message.error('添加失败,请重试');
                    }
                }
            }).catch(function (error) {
                message.error('系统繁忙');
                console.log(error)
            })
        } else {
            message.error('输入不能为空');
        }
    }
    selectCity() {
        service.request({
            url: service.api.homeURL + '/regional',
            method: "get",
            params: {
                parentCode: this.state.editorParentCode
            }
        }).then(res => {

            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ editorCityList: res.data, editorCityCode: res.data[0].Code, editorCity: res.data[0].Name, editorParentCode: res.data[0].Code, }, () => {
                    this.selectDistrict()
                })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    selectDistrict() {
        service.request({
            url: service.api.homeURL + '/regional',
            method: "get",
            params: {
                parentCode: this.state.editorParentCode
            }
        }).then(res => {
            if (res.code != 200) {
                message.error('数据加载失败，请重试');
            } else {
                this.setState({ editorAreaList: res.data, editorDistrict: res.data[0].Name, editorDistrictCode: res.data[0].Code, })
            }
        }).catch(function (error) {
            console.log(error)
        })
    }
    DeleteAddress() {
        service.request({
            url: service.api.homeURL + '/address',
            method: "delete",
            data: {
                ID: this.state.ID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.setState({ deleteAddressVisible: false })
                this.getAddress()
                message.success('删除成功');
            } else {
                message.error('删除失败，请重试');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
    setIsDefault(item) {
        service.request({
            url: service.api.homeURL + '/address/updateIsDefault',
            method: "put",
            data: {
                ID: item.ID,
            }
        }).then(res => {
            if (res.code == 200) {
                this.getAddress()
                message.success('设置成功');
            } else {
                message.error('设置失败，请重试');
            }
        }).catch(function (error) {
            message.error('系统繁忙');
            console.log(error)
        })
    }
}