import React, { Component } from 'react'
import { CloudDownloadOutlined, } from '@ant-design/icons';
import { Button, Watermark, Modal, Input, Tooltip } from 'antd';
import service from '../../../utlis/request'

import PPT from '../../../component/ppt_wbe365';
import VideoPlayer from '../../../component/video'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import '../../../index.css'
export default class CampusOperations_file extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // PhaseList: [{ Name: "启迪篇", ID: 1, }, { Name: "基础篇", ID: 2, }, { Name: "工具课程", ID: 3, }, { Name: "特色课程", ID: 4, }],
            FileList: [],
            FileParentID: 0,
            nav_arr: [],
            header: 'https://api2.yuexiaojing.com/v3/',
            FileVisible: false,
            PreviewFile: {},
            Name: "",
        }

    }
    ////////////////////////////////////////////////////////////////////////
    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "40px", width: "1200px" }}>
                {this.state.nav_arr.length > 0 ? <div style={{ display: "flex", marginBottom: "15px", position: "relative", }}>
                    {
                        this.state.nav_arr.map((item, index) => {
                            if (index < 1) {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    {item.Name}
                                </div>
                            } else {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    <span>{">"}</span>
                                    {item.Name} </div>
                            }

                        })
                    }
                    <div style={{ position: "absolute", right: "00px", top: "0px", fontSize: "25px", color: "#239bc6", display: "flex", }} >
                        <Input placeholder="输入名称进行搜索" style={{ width: "300px", height: "33px", borderRadius: "8px 0px 0px 8px", border: "none" }} value={this.state.Name} onChange={(e) => { this.setState({ Name: e.target.value },) }} />
                        <Button style={{ borderRadius: "0px 8px 8px 0px", height: "33px", background: "#47b3d8", color: "#fff" }} onClick={() => this.getFiles()}>搜索</Button>
                    </div>
                </div> : ''}
                <div style={{ width: "100%", height: "620px", marginTop: "35px", border: "1px solid #dad6d6", background: "rgb(255 255 255 / 56%)", borderRadius: "8px" }}>

                    <div style={{ width: "100%", height: "600px", overflow: "auto", display: "flex", justifyContent: "flex-start", flexWrap: "wrap", margin: "10px 0px" }}>
                        {this.state.FileList.map((item, index) => {
                            return (

                                <div key={index} style={{ width: "200px", height: "210px", background: "#fff", borderRadius: "10px", fontSize: "12px", cursor: 'pointer', position: "relative", margin: "40px 17px" }}
                                >
                                    <div style={{ height: "160px", width: "160px", border: "1px solid #f1e9e9d9", margin: "0px 20px 0px 20px", borderRadius: "6px", pading: "2px" }} onClick={() => this.switchFiles(item)}>
                                        <img loading='lazy' style={{ height: "158px", width: "158px", objectFit: "contain", }} src={item.Cover ? this.state.header + item.Cover : this.state.header + item.Path} />
                                        {item.IsDownload == 1 ? <Tooltip title="下载">
                                            <div id="111" style={{ position: "absolute", right: "21px", bottom: "45px", fontSize: "25px", color: "#239bc6", cursor: "pointer", }} onClick={(e) => this.download(e, item)}><CloudDownloadOutlined style={{ color: "#239bc6", fontSize: "20px" }} /></div>
                                            {/* {this.state.Download ? <div id="111" style={{ position: "absolute", right: "21px", bottom: "45px", fontSize: "25px", color: "#239bc6", cursor: "pointer", }} onClick={(e) => this.download(e, item)}><CloudDownloadOutlined style={{ color: "#239bc6", fontSize: "20px" }} /></div> : <></>} */}
                                        </Tooltip> : ""}
                                    </div>
                                    <div key={item.ID} style={{ height: "25px", lineHeight: "25px", textAlign: "center", fontSize: "16px", marginTop: "5px" }}>{item.Name ? (item.Name.length > 10 ? item.Name.substring(0, 10) + "..." : item.Name) : ""}</div>
                                    {/* <span style={{ height: "16px", width: "16px", position: "absolute", bottom: "55px", right: "21px" }}>
                    {item.checked && <CheckCircleFilled style={{ color: "#239bc6" }} />}
                  </span> */}
                                </div>

                            );
                        })}
                    </div>
                    <Modal
                        destroyOnClose={true}
                        centered={true}
                        title={null}
                        footer={null}
                        modalRender={(modal) => (
                            modal
                        )}
                        // bodyStyle={{ padding: '0px' }}
                        open={this.state.FileVisible}
                        onCancel={() => this.setState({ FileVisible: false })}
                        closable={true}
                        width={900}
                        style={{ minWidth: "900px" }}
                    >
                        <div style={{ height: "700px", padding: "10px 20px" }}>
                            {this.renderFile()}
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
    componentDidMount() {
        this.setState({ FileParentID: this.props.nav_arr[this.props.nav_arr.length - 1].ID, nav_arr: this.props.nav_arr }, () => {
            this.getFiles()
        })
    }
    switchPage(index) {
        if (index == 0) {
            this.setState({ nav_arr: [] }, () => {
                this.props.setPage(1, this.state.nav_arr)
            })
        } else if (index != this.state.nav_arr.length - 1) {
            var Item = this.state.nav_arr[index]
            var list = []
            this.state.nav_arr.splice(index + 1)
            list = [... this.state.nav_arr]
            this.setState({ TableItem: Item, nav_arr: list }, () => {
                this.props.setPage(1, this.state.nav_arr)
            })
        }
    }

    getFiles() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/material',
            params: {
                offset: 0,
                limit: 0,
                directoryID: this.state.FileParentID,
                name: this.state.Name,
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    FileList: res.data
                })
            } else {
            }
        })
    }
    switchFiles(item) {
        this.setState({
            FileVisible: true,
            PreviewFile: item,
        })
    }
    download(e, i) {
        e.stopPropagation()
        let a = document.createElement('a')
        a.style = 'display: none' // 创建一个隐藏的a标签
        // a.download = filename
        a.href = this.state.header + "getfile?filePath=" + i.Path
        document.body.appendChild(a)
        a.click() // 触发a标签的click事件
        document.body.removeChild(a)
    }
    renderFile() {
        var suffix = ""
        var filePath = ""
        if (this.state.PreviewFile.Path != undefined) {
            filePath = this.state.PreviewFile.Path;
            suffix = filePath.split('.').pop().toLowerCase();
        }

        switch (suffix) {
            case "ppt": case "pptx":
                return <div style={{ width: "812px", height: "680px", background: "#47b3d8", borderRadius: "12px", }}>
                    <PPT path={this.state.PreviewFile.Path} />
                </div>
            case "doc": case "docx":
                return <div style={{ width: "812px", height: "680px", background: "#47b3d8", borderRadius: "12px", }}>
                    <Watermark style={{ width: "100%", height: "100%" }} content={global.Watermark} gap={[500,500]}>
                        <iframe title="ppt" width="100%" height="100%" frameBorder="0" src={"https://vip.ow365.cn?i=30293&ssl=1&furl=" + this.state.header + this.state.PreviewFile.Path}></iframe>
                    </Watermark>
                </div>
            case "pdf":
                return <div style={{ width: "812px", height: "680px", background: "#000", borderRadius: "12px" }}>
                    <iframe title="pdf" width="100%" height="100%" frameBorder="0" src={this.state.header + this.state.PreviewFile.Path}></iframe>
                </div>
            case "zip":
                return <div style={{ width: "812px", height: "680px", background: "#fff", borderRadius: "12px", textAlign: "center", lineHeight: "600px", fontSize: "20px", }}>
                    此文件格式为zip不支持预览
                </div>
            case "mp4":
                return <div style={{ width: "812px", height: "680px", background: "#000", borderRadius: "12px" }}>
                    <VideoPlayer controls src={this.state.header + this.state.PreviewFile.Path} />
                    {/* <video preload="auto" width="100%" height="100%" controls src={this.state.header + this.state.PreviewFile.Path}>
                    </video> */}
                </div>
            case "mp3":
                return <div style={{ width: "812px", height: "680px", background: "#000", borderRadius: "12px" }}>
                    <AudioPlayer src={this.state.header + this.state.PreviewFile.Path} />
                    {/* <audio controls>
                        <source src={this.state.header + this.state.PreviewFile.Path} type="audio/ogg" />
                    </audio> */}
                </div>
            case "psd": case "ai":
                return <div style={{ width: "812px", height: "680px", background: "#000", borderRadius: "12px", }}>
                    <img style={{ width: "812px", height: "680px", objectFit: "contain", }} src={this.state.header + this.state.PreviewFile.Cover}></img>
                </div>
            case "jpeg": case "jpg": case "jpeg":
                return <div style={{ width: "812px", height: "680px", background: "#47b3d8", borderRadius: "12px" }}>
                    <img style={{ width: "812px", height: "680px", objectFit: "contain", }} src={this.state.header + this.state.PreviewFile.Path}></img>
                </div>
        }
    }
}