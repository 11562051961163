import React, { Component } from 'react'
import service from '../../../utlis/request'
export default class CampusOperations_course extends Component {
    constructor(props) {
        super(props)
        this.state = {
            CourseList: [],
            ParentID: 0,
            nav_arr: [],
            TableItem: {},
            header: 'https://api2.yuexiaojing.com/v3/'
        }

    }
    ////////////////////////////////////////////////////////////////////////

    render() {
        return (
            <div style={{ marginTop: "20px", paddingTop: "40px", width: "1200px" }}>
                {this.state.nav_arr.length > 0 ? <div style={{ display: "flex", marginBottom: "15px", }}>
                    {
                        this.state.nav_arr.map((item, index) => {
                            if (index < 1) {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    {item.Name}
                                </div>
                            } else {
                                return <div key={index} onClick={() => this.switchPage(index)} style={{ marginLeft: "5px", color: "#2a2a2a", cursor: "pointer", fontSize: "18px", }}>
                                    <span>{">"}</span>
                                    {item.Name} </div>
                            }

                        })
                    }
                </div> : ''}

                <div style={{ width: "100%", marginTop: "35px", display: "flex", flexWrap: "wrap"}}>
                    {
                        this.state.CourseList.map((item, index) => {
                            return <div className='liveContent' key={index} onClick={() => this.switchActivity(item)} index={index} style={{ width: "280px", height: "225px", cursor: "pointer", fontSize: "18px", color: "#239bc6", background: "#fff", borderRadius: "10px", marginBottom: "30px", marginRight: "20px", padding: "20px" }}>
                                <img style={{ width: "240px", height: "135px", objectFit: "contain" }} src={this.state.header + item.Cover}></img>
                                <p style={{ height: "40px", lineHeight: "40px", textAlign: "center", fontSize: "20px" }}>{item.Name ? (item.Name.length > 10 ? item.Name.substring(0, 10) + "..." : item.Name) : ""}</p>
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
    componentDidMount() {
        if (this.props.nav_arr.length > 1) {
            this.setState({ nav_arr: this.props.nav_arr, TableItem: this.props.nav_arr[this.props.nav_arr.length - 1] }, () => {
                this.getChildCourse()
            })
        } else {
            this.setState({ nav_arr: [] }, () => {
                this.getCourse()
            })
        }

    }
    getCourse() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/directory',
            params: {
                offset: 0,
                limit: 0,
                parentID: 0
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    CourseList: res.data,
                })
            } else {
            }
        })
    }
    getChildCourse() {
        service.request({
            method: 'get',
            url: service.api.homeURL + '/directory',
            params: {
                offset: 0,
                limit: 0,
                parentID: this.state.TableItem.ID
            }
        }).then(res => {
            if (res.code === 200) {
                this.setState({
                    CourseList: res.data,
                })
            } else {
            }
        })
    }
    switchPage(index) {
        if (index == 0) {
            this.getCourse()
            this.setState({ nav_arr: [] })
        } else if (index != this.state.nav_arr.length - 1) {
            var Item = this.state.nav_arr[index]
            var list = []
            this.state.nav_arr.splice(index + 1)
            list = [... this.state.nav_arr]
            this.setState({ TableItem: Item, nav_arr: list }, () => {
                this.getChildCourse()
            })
        }
    }
    switchActivity(item) {
        if (item.IsChild == 1) {
            if (this.state.nav_arr && this.state.nav_arr.length > 0) {
                this.state.nav_arr.push(item)
                this.setState({ TableItem: item }, () => {
                    this.getChildCourse()
                })
            } else {
                this.state.nav_arr.push({ Name: "校区运营" })
                this.state.nav_arr.push(item)
                this.setState({ TableItem: item }, () => {
                    this.getChildCourse()
                })
            }


        } else {
            this.state.nav_arr.push(item)
            this.setState({ TableItem: item }, () => {
                this.props.setPage(999, this.state.nav_arr)
            })

        }

        // if (item.Type == 1) {
        //     this.props.setPage(3, this.state.nav_arr)
        //     this.props.setCourse(item)
        // } else {
        //     this.props.setPage(4, this.state.nav_arr)
        //     this.props.setCourse(item)
        // }
    }
    // 鼠标事件
    enter(ID, index) {
        if ((index + 1) % 4 == 1) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #492bce"
            // e.target.style.background = "#bfecd7"
        }
        if ((index + 1) % 4 == 2) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #22d9e6"
            // e.target.style.background = "#bfecd7"
        }
        if ((index + 1) % 4 == 3) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #73f909"
            // e.target.style.background = "#bfecd7"
        }
        if ((index + 1) % 4 == 4) {
            var doc = document.getElementById(ID)
            doc.style.border = "2px solid #976c05"
            // e.target.style.background = "#bfecd7"
        }
    }
    remove(ID, index) {
        var doc = document.getElementById(ID)
        doc.style.border = "2px solid #fff"
        // e.target.style.background = 'none'
    }
}